export function setupDropdown() {
    const checkElements = () => {
        const dropdownBtn = document.getElementById("drop-text");
        const list = document.getElementById("list");
        const icon = document.getElementById("icon");
        const span = document.getElementById("span");
        const input = document.getElementById("search-input");
        const listItems = document.querySelectorAll('.dropdown-item');

        if (!dropdownBtn || !list || !icon || !span || !input) {
            console.error('One or more elements are missing:');
            console.error('dropdownBtn:', dropdownBtn);
            console.error('list:', list);
            console.error('icon:', icon);
            console.error('span:', span);
            console.error('input:', input);
            return false;
        }

        return true;
    };

    if (!checkElements()) {
        // Réessayer après un court délai si les éléments ne sont pas encore disponibles
        setTimeout(() => {
            if (checkElements()) {
                setupDropdown();
            }
        }, 100); // Ajustez le délai si nécessaire
        return () => {}; // Retourne une fonction vide si les éléments sont manquants
    }

    const dropdownBtn = document.getElementById("drop-text");
    const list = document.getElementById("list");
    const icon = document.getElementById("icon");
    const span = document.getElementById("span");
    const input = document.getElementById("search-input");
    const listItems = document.querySelectorAll('.dropdown-item');

    const handleDropdownClick = () => {
        if (list.classList.toggle('show')) {
            icon.style.transform = "rotate(0deg)";
        } else {
            icon.style.transform = "rotate(-180deg)";
        }
    };

    const handleWindowClick = (e) => {
        if (!dropdownBtn.contains(e.target) && !list.contains(e.target)) {
            list.classList.remove("show");
            icon.style.transform = "rotate(0deg)";
        }
    };

    const handleListItemClick = (e) => {
        span.innerText = e.target.innerText;

        if (e.target.innerText === "Chercher par catégorie") {
            input.placeholder = "Chercher...";
        } else {
            input.placeholder = "Chercher par " + e.target.innerText.toLowerCase();
        }

        list.classList.remove("show");
        icon.style.transform = "rotate(0deg)";
    };

    dropdownBtn.addEventListener('click', handleDropdownClick);
    window.addEventListener('click', handleWindowClick);
    listItems.forEach(item => item.addEventListener('click', handleListItemClick));

    return () => {
        dropdownBtn.removeEventListener('click', handleDropdownClick);
        window.removeEventListener('click', handleWindowClick);
        listItems.forEach(item => item.removeEventListener('click', handleListItemClick));
    };
}
