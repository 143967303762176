import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import './CommissionHeader.css';
import logo from '../../Assets/FSAC_mini.png';
import { AuthContext } from '../../Context/AuthContext';

const CommissionHeader = () => {
    const navigate = useNavigate();
    const { logout } = useContext(AuthContext);

    const handleLogout = () => {
        logout();
        navigate('/');
    };

    return (
        <header className="commission-header">
            <div className="header-container">
                <div className="logo">
                    <Link to="/membre-commision"><img src={logo} alt="FSAC Logo" /></Link>
                </div>
                <nav className="nav">
                    <ul>
                        <li><Link to="/membre-commision/change-password"><FontAwesomeIcon icon={faUser} /> Changer le mot de passe</Link></li>
                        <li><Link to="#" onClick={handleLogout}><FontAwesomeIcon icon={faSignOutAlt} /> Déconnexion</Link></li>
                    </ul>
                </nav>
            </div>
        </header>
    );
};

export default CommissionHeader;
