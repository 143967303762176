import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import './AdminHeader.css';
import logo from '../../Assets/FSAC_mini.png';
import { AuthContext } from '../../Context/AuthContext';

const AdminHeader = ({ onOpenCreateUserModal }) => {  
    const { logout } = useContext(AuthContext);

    const handleLogout = () => {
        logout();
        window.history.pushState(null, null, window.location.href);
        window.addEventListener('popstate', () => {
            window.location.href = '/';
        });
    };

    return (
        <div className="admin-header">
            <div className="header-container">
                <div className="logo">
                    <a href="/admin"><img src={logo} alt="FSAC Logo" /></a>
                </div>
                <nav className="nav">
                    <ul>
                        <li>
                            <button onClick={onOpenCreateUserModal} className="nav-link" id="test">
                                <FontAwesomeIcon icon={faUserPlus} /> Ajouter un Utilisateur
                            </button>
                        </li>
                        <li>
                            <button onClick={handleLogout} className="nav-link" id="test">
                                <FontAwesomeIcon icon={faSignOutAlt} /> Déconnexion
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default AdminHeader;
