import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './Context/AuthContext';
import AdminPage from './components/Admin/AdminPage';
import Login from './components/Authentification/Login';
import ForgotPassword from './components/Authentification/ForgotPassword';
import ResetPassword from './components/Authentification/ResetPassword';
import EnseignantChercheurPage from './components/Enseignant/EnseignantChercheurPage';
import Coordonnateur from './components/Coordonnateur/Coordonnateur';
import Commission from './components/Commission/Commission';
import ProtectedRoute from './components/ProtectedRoute';
import Home from './components/Home/Home';
import Header from './components/Header/Header';
import TeacherHeader from './components/Enseignant/TeacherHeader';
import CoordonnateurHeader from './components/Coordonnateur/CoordonnateurHeader';
import Footer from './components/Footer/Footer';
import NotFound from './components/NotFound/NotFound';
import ChangePassword from './components/Enseignant/ChangePassword';
import DepotDossier from './components/Enseignant/DepotDossier';
import TrackDossier from './components/Enseignant/TrackDossier';
import history from './history';
import Evaluation from './components/Commission/Evaluation';
import CommissionHeader from './components/Commission/CommissionHeader';
import ViewDossier from './components/Enseignant/ViewDossier'; 
import UpdateDossier from './components/Enseignant/UpdateDossierEnseignant';

function App() {
  return (
    <Router history={history}>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<><Header /><Home /><Footer /></>} />
          <Route path="/login" element={<><Header /><Login /><Footer /></>} />
          <Route path="/forgot-password" element={<><Header /><ForgotPassword /><Footer /></>} />
          <Route path="/reset-password" element={<><Header /><ResetPassword /><Footer /></>} />
          <Route path="/admin" element={<ProtectedRoute element={<><AdminPage /><Footer /></>} />} />
          <Route path="/enseignant" element={<ProtectedRoute element={<><TeacherHeader /><EnseignantChercheurPage /><Footer /></>} />} />
          <Route path="/enseignant/change-password" element={<ProtectedRoute element={<><TeacherHeader /><ChangePassword /><Footer /></>} />} />
          <Route path="/enseignant/depot-dossier" element={<ProtectedRoute element={<><TeacherHeader /><DepotDossier /><Footer /></>} />} />
          <Route path="/enseignant/track-dossier" element={<ProtectedRoute element={<><TeacherHeader /><TrackDossier /><Footer /></>} />} />
          <Route path="/enseignant/track-dossier/view/:dossierId" element={<ProtectedRoute element={<><TeacherHeader /><ViewDossier /><Footer /></>} />} />
          <Route path="/enseignant/track-dossier/update/:dossierId" element={<ProtectedRoute element={<><TeacherHeader /><UpdateDossier /><Footer /></>} />} />

          <Route path="/coordonnateur" element={<ProtectedRoute element={<><CoordonnateurHeader /><Coordonnateur /><Footer /></>} />} />
          <Route path="/coordonnateur/change-password" element={<ProtectedRoute element={<><CoordonnateurHeader /><ChangePassword /><Footer /></>} />} />
          <Route path="/membre-commision" element={<ProtectedRoute element={<><CommissionHeader /><Commission /><Footer /></>} />} />
          <Route path="/membre-commision/evaluation" element={<ProtectedRoute element={<><CommissionHeader /><Evaluation /><Footer /></>} />} />
          <Route path="/membre-commision/evaluation/:dossierId" element={<ProtectedRoute element={<><CommissionHeader /><Evaluation /><Footer /></>} />} />

          <Route path="*" element={<><Header /><NotFound /><Footer /></>} />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
