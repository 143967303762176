import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../Context/AuthContext';
import NestedDropdown from './NestedDropdown';
import PieceJustificativeModal from './PieceJustificativeModal';
import dossierService from '../../services/dossierService';
import './DepotDossier.css';
import { FaTrashAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { FaRedo } from 'react-icons/fa';  // Import FaRedo icon
import { Base64 } from 'js-base64';  // Import the library

function DepotDossier() {
  const { userId } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);  // Define the loading state


  const [formData, setFormData] = useState({
    statut: "EN_COURS_DE_TRAITEMENT",
    enseignantChercheur: {
      id: userId || null,
    },
    activitesEnseignement: {
      description: "AE - Activité d'enseignement",
      productionPedagogiques: [],
      encadrementPedagogiques: [],
      responsabilitesPedagogiques: [],
      piecesJustificatives: [],
    },
    activitesRecherche: {
      description: "AR - Activité de recherche",
      productionScientifiques: [],
      encadrementScientifiques: [],
      responsabilitesScientifiques: [],
      ouverturesValorisations: [],
      piecesJustificatives: [],
    },
    annexes: [],
    commentaire: '',
    dueDate: null,
  });
  const activityOptions = [
    {
      text: 'AE - Activité d\'enseignement',
      subOptions: [
        {
          text: 'AE 1 - Production pédagogique',
          subOptions: [
            { text: 'AE 1-1 : Publication d\'ouvrages d\'enseignement (ISSN ou ISBN).' },
            { text: 'AE 1-2 : Manuels ou polycopiés d’enseignement (validés par le département d’attache et distribués aux étudiants).' },
            { text: 'AE 1-3 : Supports NTIC et Innovations pédagogiques (diaporamas, didacticiels, mise en place de nouveaux TPs, page web ou homepage à caractère pédagogique).' }
          ]
        },
        {
          text: 'AE 2 - Encadrement pédagogique',
          subOptions: [
            { text: 'AE 2-1 : Encadrement de cours, TD, TP et PFE (cycle Licence).' },
            { text: 'AE 2-2 : Encadrement de cours et mémoires de fin d’étude (cycle Master).' },
            { text: 'AE 2-3 : Encadrement d’autres activités (sorties de terrain, animation de séminaires, formations,…).' }
          ]
        },
        {
          text: 'AE 3 - Responsabilités pédagogiques',
          subOptions: [
            { text: 'AE 3-1 : Chef de département ou membre de collège (ou bureau) du département.' },
            { text: 'AE 3-2 : Coordonnateur de filière.' },
            { text: 'AE 3-3 : Responsable de module.' },
            { text: 'AE 3-4 : Membre du conseil d’Etablissement.' },
            { text: 'AE 3-5 : Membre du conseil d’Université.' },
            { text: 'AE 3-6 : Membre de la commission scientifique.' },
            { text: 'AE 3-7 : Membre de commission d\'expertise ou d\'évaluation pédagogique à l’échelle nationale ou internationale.' }
          ]
        },
        {
          text: 'AE - Pièces justificatives'
        }
      ]
    },
    {
      text: 'AR - Activité de recherche',
      subOptions: [
        {
          text: 'AR 1 - Production scientifique',
          subOptions: [
            { text: 'AR 1-1 : Publications scientifiques dans des revues spécialisées indexées (Scopus, Web of Knowledge).' },
            { text: 'AR 1-2 : Publications dans les actes de manifestations scientifiques avec comité de lecture.' },
            { text: 'AR 1-3 : Ouvrages de recherche édités (livres ou chapitres de livres).' },
            { text: 'AR 1-4 : Publications scientifiques dans des revues spécialisées nationales ou internationales avec comité de lecture.' }
          ]
        },
        {
          text: 'AR 2 - Encadrement scientifique',
          subOptions: [
            { text: 'AR 2-1 : Direction ou codirection de thèses de Doctorat.' },
            { text: 'AR 2-2 : Rapporteur et membre de jury de thèses ou d’habilitations.' }
          ]
        },
        {
          text: 'AR 3 - Responsabilités scientifiques',
          subOptions: [
            { text: 'AR 3-1 : Directeur de structure de recherche (laboratoire, pôle de compétence, centre thématique de recherche,…).' },
            { text: 'AR 3-2 : Responsable d\'équipe de recherche.' },
            { text: 'AR 3-3 : Responsable ou participant dans des projets ou contrats de recherche financés.' },
            { text: 'AR 3-4 : Activités d’expertise et d’évaluation scientifique à l’échelle nationale ou internationale (CNACES, CNRST, reviewer, editorial board, editor-in-chief,…).' }
          ]
        },
        {
          text: 'AR 4 - Ouverture et valorisation',
          subOptions: [
            { text: 'AR 4-1 : Animation de structures d’interaction avec le milieu socio-économique et organisation de manifestations scientifiques (séminaires, colloques, congrès, forums,…).' },
            { text: 'AR 4-2 : Expertise et valorisation d\'actions et de travaux au profit du milieu socio-économique (organisations non gouvernementales, secteur privé, organismes internationaux, secteur public).' },
            { text: 'AR 4-3 : Travaux de recherche et innovations (Dépôt de brevets au profit de l’établissement, conception de prototypes, incubation de projets de recherche et de développement, prix,…).' }
          ]
        },
        {
          text: 'AR - Pièces justificatives'
        }
      ]
    }
  ];

  const annexesOptions = [
    { text: 'Annexe I' },
    { text: 'Annexe II' },
    { text: 'Annexe III' }
  ];
  const [showModal, setShowModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedAnnex, setSelectedAnnex] = useState('');

  useEffect(() => {
    if (userId) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        enseignantChercheur: {
          ...prevFormData.enseignantChercheur,
          id: userId,
        },
      }));
    }
  }, [userId]);

  const handleOptionSelect = (optionText) => {
    setSelectedOption(optionText);
    if (optionText === 'AE - Pièces justificatives' || optionText === 'AR - Pièces justificatives') {
      setShowModal(true);
    } else {
      document.getElementById('fileInput').click();
    }
  };

  const handleAnnexSelect = (optionText) => {
    setSelectedAnnex(optionText);
    document.getElementById('fileInput').click();
  };
  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Convert the binary data to a Base64 string using js-base64
        //const base64String = Base64.encode(reader.result);
        const base64String = reader.result.split(',')[1];
        resolve(base64String);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
      //reader.readAsBinaryString(file); // Use readAsBinaryString instead of readAsDataURL
    });
  };




  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);  // Start loading
    console.log("data before submit" + formData);
    try {
      await dossierService.submitDossier(formData);
      alert("Dossier déposé");

      // Redirect to the /enseignant/track-dossier page
      navigate('/enseignant/track-dossier');
    } catch (error) {
      console.error("Erreur lors de la soumission du dossier:", error);
      alert("Erreur lors de la soumission du dossier");
    } finally {
      setLoading(false);  // Stop loading
    }
  };





  const handleFileUpload = async (event) => {
    const uploadedFiles = Array.from(event.target.files);

    const newFormData = { ...formData };

    const addFilesToSection = async (sectionPath, files) => {
      const pathKeys = sectionPath.split('.');
      let section = newFormData;

      pathKeys.forEach((key, index) => {
        if (!section[key]) {
          section[key] = index === pathKeys.length - 1 ? [] : {};
        }
        section = section[key];
      });

      for (const file of files) {
        const base64Content = await convertFileToBase64(file); // Convert to Base64
        const newAxe = {
          titre: selectedOption,
          note: null,
          contenu: base64Content, // Store only the Base64 string
          fileName: file.name, // Store file name in the frontend
        };

        if (!section[0]) {
          section.push({ axes: [] });
        }

        const fileExists = section[0].axes.some(
          (axe) => axe.contenu === base64Content && axe.titre === newAxe.titre
        );

        if (!fileExists) {
          section[0].axes.push(newAxe);
        }
      }
    };

    const handleAnnexes = async (files) => {
      for (const file of files) {
        const base64Content = await convertFileToBase64(file); // Convert to Base64
        const fileExists = newFormData.annexes.some((annexe) => annexe.contenu === base64Content);

        if (!fileExists) {
          newFormData.annexes.push({
            typeAnnexe: selectedAnnex,
            contenu: base64Content, // Store only the Base64 string
            fileName: file.name, // Store file name in the frontend
          });
        }
      }
    };

    if (selectedAnnex) {
      await handleAnnexes(uploadedFiles);
    } else if (selectedOption) {
      if (selectedOption.startsWith('AE 1')) {
        await addFilesToSection('activitesEnseignement.productionPedagogiques', uploadedFiles);
      } else if (selectedOption.startsWith('AE 2')) {
        await addFilesToSection('activitesEnseignement.encadrementPedagogiques', uploadedFiles);
      } else if (selectedOption.startsWith('AE 3')) {
        await addFilesToSection('activitesEnseignement.responsabilitesPedagogiques', uploadedFiles);
      } else if (selectedOption === 'AE - Pièces justificatives') {
        await addFilesToSection('activitesEnseignement.piecesJustificatives', uploadedFiles);
      } else if (selectedOption.startsWith('AR 1')) {
        await addFilesToSection('activitesRecherche.productionScientifiques', uploadedFiles);
      } else if (selectedOption.startsWith('AR 2')) {
        await addFilesToSection('activitesRecherche.encadrementScientifiques', uploadedFiles);
      } else if (selectedOption.startsWith('AR 3')) {
        await addFilesToSection('activitesRecherche.responsabilitesScientifiques', uploadedFiles);
      } else if (selectedOption.startsWith('AR 4')) {
        await addFilesToSection('activitesRecherche.ouverturesValorisations', uploadedFiles);
      } else if (selectedOption === 'AR - Pièces justificatives') {
        await addFilesToSection('activitesRecherche.piecesJustificatives', uploadedFiles);
      }
    }

    setFormData(newFormData);
    setSelectedOption('');
    setSelectedAnnex('');
  };



  const handleDelete = (sectionPath, index) => {
    setFormData((prevState) => {
      // Deep copy the state to avoid mutation issues
      const newFormData = JSON.parse(JSON.stringify(prevState));
      const keys = sectionPath.split('.');
      const section = keys.reduce((acc, key) => acc[key], newFormData);

      // Use filter to remove the item at the specified index
      section.splice(index, 1);

      return newFormData;
    });
  };






  const handleModalSave = async (pieceType, file) => {
    const base64Content = await convertFileToBase64(file);

    setFormData((prevState) => {
      const newFormData = { ...prevState };

      if (selectedOption === 'AE - Pièces justificatives') {
        newFormData.activitesEnseignement.piecesJustificatives.push({
          typePiece: pieceType,
          contenu: base64Content,
        });
      } else if (selectedOption === 'AR - Pièces justificatives') {
        newFormData.activitesRecherche.piecesJustificatives.push({
          typePiece: pieceType,
          contenu: base64Content,
        });
      }

      return newFormData;
    });

    setShowModal(false);
  };



  return (
    <div className='mainDepotDossier-container'>
      <div className='depotdossier-container'>
        <h1 className='titleDossier'>Déposer un dossier</h1>
        <form className="depotdossier-form" onSubmit={handleSubmit}>
          <div className='dropdowns-section'>
            <NestedDropdown
              options={activityOptions}
              defaultOption="Sélectionner une activité"
              onOptionSelect={handleOptionSelect}
            />
            <NestedDropdown
              options={annexesOptions}
              defaultOption="Sélectionner une annexe"
              onOptionSelect={handleAnnexSelect}
            />
          </div>
          <input
            id="fileInput"
            type="file"
            multiple
            onChange={handleFileUpload}
            style={{ display: 'none' }}
          />


          <PieceJustificativeModal
            show={showModal}
            handleClose={() => setShowModal(false)}
            handleSave={handleModalSave}
          />
          {loading ? (
            <button type="button" disabled>
              <FaRedo className="forgot-icon spin" /> Déposer en cours...
            </button>
          ) : (
            <button type="submit" className="depotdossier-submit">
              <FaRedo className="forgot-icon" /> Déposer
            </button>
          )}

        </form>
      </div>
      <div className='uploadTable-container'>
        <div className='file-table-container depotdossier-table'>
          <h2 className='titleDossierII'>Fichiers téléchargés</h2>
          <table className='table table-striped table-hover table-bordered'>
            <thead>
              <tr>
                <th>Section</th>
                <th>Nom des fichiers</th>
                <th>Supprimer</th>
              </tr>
            </thead>
            <tbody>
              {/* Render activitesEnseignement.productionPedagogiques */}
              {formData.activitesEnseignement.productionPedagogiques.flatMap((prod, prodIndex) =>
                prod.axes.map((a, axisIndex) => (
                  <tr key={`prod-${prodIndex}-${axisIndex}`}>
                    <td>{a.titre || 'No Title'}</td>
                    <td>{a.fileName || 'No File'}</td> {/* Display file name */}
                    <td>
                      <FaTrashAlt
                        className="delete-button"
                        onClick={() =>
                          handleDelete(
                            `activitesEnseignement.productionPedagogiques.${prodIndex}.axes`,
                            axisIndex
                          )
                        }
                      />
                    </td>
                  </tr>
                ))
              )}

              {/* Render activitesEnseignement.encadrementPedagogiques */}
              {formData.activitesEnseignement.encadrementPedagogiques.flatMap((encadrement, encIndex) =>
                encadrement.axes.map((a, axisIndex) => (
                  <tr key={`encadrement-${encIndex}-${axisIndex}`}>
                    <td>{a.titre || 'No Title'}</td>
                    <td>{a.fileName || 'No File'}</td> {/* Display file name */}
                    <td>
                      <FaTrashAlt
                        className="delete-button"
                        onClick={() =>
                          handleDelete(
                            `activitesEnseignement.encadrementPedagogiques.${encIndex}.axes`,
                            axisIndex
                          )
                        }
                      />
                    </td>
                  </tr>
                ))
              )}

              {/* Render activitesEnseignement.responsabilitesPedagogiques */}
              {formData.activitesEnseignement.responsabilitesPedagogiques.flatMap((responsabilite, respIndex) =>
                responsabilite.axes.map((a, axisIndex) => (
                  <tr key={`responsabilite-${respIndex}-${axisIndex}`}>
                    <td>{a.titre || 'No Title'}</td>
                    <td>{a.fileName || 'No File'}</td> {/* Display file name */}
                    <td>
                      <FaTrashAlt
                        className="delete-button"
                        onClick={() =>
                          handleDelete(
                            `activitesEnseignement.responsabilitesPedagogiques.${respIndex}.axes`,
                            axisIndex
                          )
                        }
                      />
                    </td>
                  </tr>
                ))
              )}

              {/* Render activitesEnseignement.piecesJustificatives */}
              {formData.activitesEnseignement.piecesJustificatives.map((piece, index) => (
                <tr key={`ae-piece-${index}`}>
                  <td>AE - Pièces justificatives</td>
                  <td>{piece.typePiece || 'No Title'}</td>
                  <td>
                    <FaTrashAlt
                      className="delete-button"
                      onClick={() => handleDelete('activitesEnseignement.piecesJustificatives', index)}
                    />
                  </td>
                </tr>
              ))}

              {/* Render activitesRecherche.productionScientifiques */}
              {formData.activitesRecherche.productionScientifiques.flatMap((prod, prodIndex) =>
                prod.axes.map((a, axisIndex) => (
                  <tr key={`recherche-prod-${prodIndex}-${axisIndex}`}>
                    <td>{a.titre || 'No Title'}</td>
                    <td>{a.fileName || 'No File'}</td> {/* Display file name */}
                    <td>
                      <FaTrashAlt
                        className="delete-button"
                        onClick={() =>
                          handleDelete(
                            `activitesRecherche.productionScientifiques.${prodIndex}.axes`,
                            axisIndex
                          )
                        }
                      />
                    </td>
                  </tr>
                ))
              )}

              {/* Render activitesRecherche.encadrementScientifiques */}
              {formData.activitesRecherche.encadrementScientifiques.flatMap((encadrement, encIndex) =>
                encadrement.axes.map((a, axisIndex) => (
                  <tr key={`recherche-encadrement-${encIndex}-${axisIndex}`}>
                    <td>{a.titre || 'No Title'}</td>
                    <td>{a.fileName || 'No File'}</td> {/* Display file name */}
                    <td>
                      <FaTrashAlt
                        className="delete-button"
                        onClick={() =>
                          handleDelete(
                            `activitesRecherche.encadrementScientifiques.${encIndex}.axes`,
                            axisIndex
                          )
                        }
                      />
                    </td>
                  </tr>
                ))
              )}

              {/* Render activitesRecherche.responsabilitesScientifiques */}
              {formData.activitesRecherche.responsabilitesScientifiques.flatMap((responsabilite, respIndex) =>
                responsabilite.axes.map((a, axisIndex) => (
                  <tr key={`recherche-responsabilite-${respIndex}-${axisIndex}`}>
                    <td>{a.titre || 'No Title'}</td>
                    <td>{a.fileName || 'No File'}</td> {/* Display file name */}
                    <td>
                      <FaTrashAlt
                        className="delete-button"
                        onClick={() =>
                          handleDelete(
                            `activitesRecherche.responsabilitesScientifiques.${respIndex}.axes`,
                            axisIndex
                          )
                        }
                      />
                    </td>
                  </tr>
                ))
              )}

              {/* Render activitesRecherche.ouverturesValorisations */}
              {formData.activitesRecherche.ouverturesValorisations.flatMap((ouverture, ouvIndex) =>
                ouverture.axes.map((a, axisIndex) => (
                  <tr key={`recherche-ouverture-${ouvIndex}-${axisIndex}`}>
                    <td>{a.titre || 'No Title'}</td>
                    <td>{a.fileName || 'No File'}</td> {/* Display file name */}
                    <td>
                      <FaTrashAlt
                        className="delete-button"
                        onClick={() =>
                          handleDelete(
                            `activitesRecherche.ouverturesValorisations.${ouvIndex}.axes`,
                            axisIndex
                          )
                        }
                      />
                    </td>
                  </tr>
                ))
              )}

              {/* Render activitesRecherche.piecesJustificatives */}
              {formData.activitesRecherche.piecesJustificatives.map((piece, index) => (
                <tr key={`ar-piece-${index}`}>
                  <td>AR - Pièces justificatives</td>
                  <td>{piece.typePiece || 'No Title'}</td>
                  <td>
                    <FaTrashAlt
                      className="delete-button"
                      onClick={() => handleDelete('activitesRecherche.piecesJustificatives', index)}
                    />
                  </td>
                </tr>
              ))}

              {/* Render annexes */}
              {formData.annexes.map((annexe, index) => (
                <tr key={`annexe-${index}`}>
                  <td>{annexe.typeAnnexe || 'No Title'}</td>
                  <td>{annexe.fileName || 'No File'}</td> {/* Display file name */}
                  <td>
                    <FaTrashAlt
                      className="delete-button"
                      onClick={() => handleDelete('annexes', index)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default DepotDossier;

