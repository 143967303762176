import axios from 'axios';

//const API_URL = 'http://localhost:8081';
const API_URL = 'https://fsac.pro:8081';
const SEARCH_URL = `${API_URL}/search-users`;
const CHANGE_ROLE_URL = `${API_URL}/admin/change-role`;
const CREATE_USER_URL = `${API_URL}/admin/create-user`;

const createUser = async (userData) => {
    try {
        const response = await axios.post(CREATE_USER_URL, userData, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });
        console.log('User Created:', response.data);
        return response.data;
    } catch (error) {
        console.error('Error creating user:', error);
        throw error;
    }
};

const getAllUsers = async () => {
    try {
        const response = await axios.get(API_URL, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });
        console.log('API Response:', response.data); 
        return response.data;
    } catch (error) {
        console.error('Error fetching users:', error);
        throw error;
    }
};

const searchUsers = async (category, query) => {
    try {
        const response = await axios.post(SEARCH_URL, { category, query }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error searching users:', error);
        throw error;
    }
};

const changeUserRole = async (userId, newRole) => {
    try {
        const response = await axios.post(CHANGE_ROLE_URL, { userId, newRole }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });
        console.log('API Response:', response.data); 
        return response.data;
    } catch (error) {
        console.error('Error changing user role:', error);
        throw error;
    }
};

const getPaginatedUsers = async (page = 0, size = 10) => {
    try {
        const response = await axios.get(`${API_URL}/admin/all-paginated`, {
            params: { page, size },
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching paginated users:', error);
        throw error;
    }
};

const userService = {
    createUser,
    getAllUsers,
    searchUsers,
    changeUserRole,
    getPaginatedUsers,
};

export default userService;
