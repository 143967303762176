import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faFax, faEnvelope, faExternalLink, faCircle } from '@fortawesome/free-solid-svg-icons';
import { faFacebookSquare, faYoutube, faTwitter, faInstagram, faFlickr } from '@fortawesome/free-brands-svg-icons';
import './Footer.css';
import logo from '../../Assets/FSAC_mini.png';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-top">
        <h2>Suivez la FSAC</h2>
        <div className="social-icons">
          <a href="https://www.facebook.com/FSAC2020/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebookSquare} />
          </a>
          <a href="https://www.youtube.com/channel/UCLqjRJDvOXgWXcXZpenExZw" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faYoutube} />
          </a>
          <a href="#" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTwitter} />
          </a>
          <a href="#" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a href="https://www.flickr.com/people/188979619@N02/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFlickr} />
          </a>
        </div>
      </div>
      <div className="footer-middle">
        <div className="footer-main">
          <div className="footer-section large">
            <img src={logo} alt="FSAC logo" className="footer-logo"/><br/>
            <p>Faculté des sciences Aïn Chock de Casablanca</p>
            <p><span>Adresse</span><br/>Km 8 Route d'El Jadida, B.P 5366 Maarif Casablanca 20100 Maroc</p>
            <p><span>Téléphone</span><br/> <FontAwesomeIcon icon={faPhone} /> +212 5 22 23 06 80 / 84</p>
            <p><span>Fax</span><br/> <FontAwesomeIcon icon={faFax} /> +212 5 22 23 06 74</p>
          </div>
          <div className="vertical-line"></div>
          <div className="footer-section">
            <h4>Présentation</h4>
            <ul>
              <li><a href="https://fsac.ac.ma/front/tabs.html?page=presentation#Ledoyen" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faCircle} /> <span>Le doyen</span></a></li>
              <li><a href="https://fsac.univh2c.ma/front/tabs.html?page=presentation#StaffAdministratif" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faCircle} /><span> Staff Administratif</span></a></li>
              <li><a href="https://fsac.univh2c.ma/front/tabs.html?page=presentation#Historique" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faCircle} /> <span>Historique</span></a></li>
              <li><a href="https://fsac.univh2c.ma/front/tabs.html?page=presentation#Organigramme" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faCircle} /><span> Organigramme</span></a></li>
              <li><a href="https://fsac.ac.ma/front/conseil-detablissement" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faCircle} /><span> Conseil d'établissement</span></a></li>
              <li><a href="https://fsac.univh2c.ma/front/tabs.html?page=presentation#Conseild%C3%A9tablissement" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faCircle} /><span> Réglements</span></a></li>
            </ul>
          </div>
          <div className="footer-section">
            <h4>Départements</h4>
            <ul>
              <li><a href="https://fsac.univh2c.ma/front/tabs.html?page=departements#Math%C3%A9matiquesInformatique" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faCircle} /><span> Mathématiques & <br/>Informatique</span></a></li>
              <li><a href="https://fsac.univh2c.ma/front/tabs.html?page=departements#Physique" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faCircle} /><span> Physique</span></a></li>
              <li><a href="https://fsac.univh2c.ma/front/tabs.html?page=departements#Chimie" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faCircle} /> <span>Chimie</span></a></li>
              <li><a href="https://fsac.univh2c.ma/front/tabs.html?page=departements#Biologie" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faCircle} /><span> Biologie</span></a></li>
              <li><a href="https://fsac.univh2c.ma/front/tabs.html?page=departements#G%C3%A9ologie" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faCircle} /><span> Géologie</span></a></li>
            </ul>
          </div>
          <div className="footer-section">
            <h4>Offre de Formation</h4>
            <ul>
              <li><a href="https://fsac.univh2c.ma/front/tabs.html?page=formations#Formationinitiale" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faCircle} /><span> Formation initiale</span></a></li>
              <li><a href="https://fsac.univh2c.ma/front/tabs.html?page=formations#Formationcontinue" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faCircle} /><span> Formation continue</span></a></li>
            </ul>
          </div>
          <div className="footer-section">
            <h4>Recherche Scientifique</h4>
            <ul>
              <li><a href="https://fsac.univh2c.ma/front/tabs.html?page=recherche" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faCircle} /><span> CED</span></a></li>
              <li><a href="https://fsac.univh2c.ma/front/tabs.html?page=recherche#Laboratoires" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faCircle} /><span> Laboratoires</span></a></li>
              <li><a href="https://fsac.univh2c.ma/front/tabs.html?page=recherche#Centres" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faCircle} /><span> Centres</span></a></li>
              <li><a href="https://fsac.univh2c.ma/front/tabs.html?page=recherche#Documents" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faCircle} /><span> Documents</span></a></li>
            </ul>
          </div>
          <div className="footer-section">
            <h4>Espace Étudiant</h4>
            <ul>
              <li><a href="https://fsac.univh2c.ma/front/tabs.html?page=espace_etu" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faCircle} /><span> Applications</span></a></li>
              <li><a href="https://fsac.univh2c.ma/front/tabs.html?page=espace_etu#Emploisdutemps" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faCircle} /><span> Emplois du temps</span></a></li>
              <li><a href="https://fsac.univh2c.ma/front/tabs.html?page=espace_etu#Documents" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faCircle} /><span> Documents</span></a></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="footer-links">
          <p>
            <a href="contact.html">
              <FontAwesomeIcon icon={faEnvelope} /> Contact
            </a>
          </p>
          <p>
            <a href="https://ent.univh2c.ma" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faExternalLink} /> Accès ENT
            </a>
          </p>
        </div>
        <p>&copy; 2024, FSAC Casablanca. Université Hassan II</p>
      </div>
    </footer>
  );
};

export default Footer;
