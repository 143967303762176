import React, { useState } from 'react';
import './EditUserRoleModal.css';

const EditUserRoleModal = ({ userId, currentRole, onSave, onClose, currentUserRole }) => {
    const [newRole, setNewRole] = useState(currentRole);

    const handleChangeRole = (e) => {
        setNewRole(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSave(userId, newRole);
    };

    // Déterminez si l'utilisateur est admin ou non
    const isAdmin = currentUserRole === 'ADMIN'; 

    return (
        <div className="modal-overlay">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Modifier le rôle de l'utilisateur</h5>
                        <button type="button" className="btn-close" onClick={onClose}></button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="userId" className="form-label">
                                    ID de l'utilisateur
                                </label>
                                <input
                                    type="text"
                                    id="userId"
                                    className="form-control"
                                    value={userId}
                                    disabled
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="newRole" className="form-label">
                                    Nouveau rôle
                                </label>
                                <select
                                    id="newRole"
                                    className="form-select"
                                    value={newRole}
                                    onChange={handleChangeRole}
                                    disabled={isAdmin} // Désactiver la sélection si c'est un admin
                                >
                                    <option value="ENSEIGNANT_CHERCHEUR">Enseignant chercheur</option>
                                    <option value="COORDONNATEUR">Coordonnateur</option>
                                    <option value="MEMBRE_COMMISSION">Membre de commission</option>
                                </select>
                            </div>
                            {!isAdmin && (
                                <button type="submit" className="btn btn-primary">
                                    Sauvegarder
                                </button>
                            )}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditUserRoleModal;
