import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const RejectionModal = ({ show, onHide, onSave }) => {
    const [rejectionComment, setRejectionComment] = useState('');

    const handleSave = () => {
        onSave(rejectionComment);
        setRejectionComment(''); // Clear the comment after saving
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Rejet du dossier</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group controlId="rejectionComment">
                    <Form.Label>Motif du rejet</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        value={rejectionComment}
                        onChange={(e) => setRejectionComment(e.target.value)}
                        placeholder="Expliquez pourquoi le dossier est rejeté"
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Annuler
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Sauvegarder
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default RejectionModal;
