import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import dossierService from '../../services/dossierService';
import { AuthContext } from '../../Context/AuthContext';
import './Commission.css';
import { useNavigate } from 'react-router-dom';


const Commission = () => {
    const [dossiers, setDossiers] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const { userId } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchDossiers = async () => {
            if (!userId) {
                setError("User ID is not available");
                setLoading(false);
                return;
            }

            try {
                const data = await dossierService.getAssignedDossiers(userId);
                setDossiers(data); // Update state with fetched data
                setLoading(false);
            } catch (error) {
                setError(error.message); // Display error message
                console.error('Error fetching assigned dossiers:', error);
                setLoading(false);
            }
        };

        if (userId) { // Only call fetchDossiers if userId is available
            fetchDossiers();
        }
    }, [userId]);

    const handleEvaluateClick = (dossier) => {
        if (dossier.statut === 'VALIDE') {
            alert('Le dossier a déjà été validé.');
        } else {
            navigate(`/membre-commision/evaluation/${dossier.id}`);
        }
    };

    // Function to dynamically set the button background color based on status
    const getButtonStyle = (statut) => {
        switch (statut) {
            case 'EN_COURS_DE_TRAITEMENT':
                return { backgroundColor: '#2c3e50', color: 'white' };
            case 'REJETE':
                return { backgroundColor: 'red', color: 'white' };
            case 'VALIDE':
                return { backgroundColor: 'green', color: 'white' };
            default:
                return { backgroundColor: '#ccc', color: 'black' }; // Default style
        }
    };


    return (
        <div className="commission-container">
            <fieldset className="commission-fieldset">
                <legend>Dossiers Assignés</legend>
                {loading && <p>Loading...</p>}
                {error && <p className="error">Error fetching dossiers: {error}</p>}
                {!loading && !error && dossiers.length === 0 && <p>No dossiers available.</p>}
                <table className="commission-results-table">
                    <thead>
                        <tr>
                            <th>Dossier ID</th>
                            <th>Nom de l'enseignant</th>
                            <th>Prénom de l'enseignant</th>
                            <th>Date de dépôt</th>
                            <th>Date d'échéance</th>
                            <th>Statut</th>
                            <th>Évaluation</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dossiers.map(dossier => (
                            <tr key={dossier.id}>
                                <td>{dossier.id}</td>
                                <td>{dossier.enseignantChercheur.nom}</td>
                                <td>{dossier.enseignantChercheur.prenom}</td>
                                <td>{new Date(dossier.dateDepot).toLocaleDateString()}</td>
                                <td>{new Date(dossier.dueDate).toLocaleDateString()}</td>
                                <td>{dossier.statut}</td>
                                <td>
                                    
                                    <button 
                                        className='commission-evaluer-bouton' 
                                        style={getButtonStyle(dossier.statut)} 
                                        onClick={() => handleEvaluateClick(dossier)}>
                                        Évaluer
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </fieldset>
        </div>
    );
};

export default Commission;
