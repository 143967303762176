// src/components/ProtectedRoute.js
import React, { useContext, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../Context/AuthContext';

const ProtectedRoute = ({ element }) => {
  const { isAuthenticated } = useContext(AuthContext);
  const location = useLocation();

  useEffect(() => {
    const mainRoutes = ['/enseignant', '/coordonnateur', '/commission', '/admin'];
    const isMainRoute = mainRoutes.includes(location.pathname);

    if (isMainRoute) {
      // Push a dummy entry to the history stack to prevent back navigation
      window.history.pushState(null, '', window.location.href);

      // Add a popstate listener to block back navigation
      const handlePopState = () => {
        if (isMainRoute) {
          window.history.pushState(null, '', window.location.href);
        }
      };

      window.addEventListener('popstate', handlePopState);

      // Cleanup the event listener on unmount
      return () => {
        window.removeEventListener('popstate', handlePopState);
      };
    }
  }, [location.pathname]);

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return element;
};

export default ProtectedRoute;
