// utils/auth.js

const getToken = () => {
  return localStorage.getItem('token');
};

const setToken = (token, expirationTimestamp) => {
  localStorage.setItem('token', token);
  localStorage.setItem('tokenExpiration', expirationTimestamp);
};

const removeToken = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('tokenExpiration');
};

const parseJwt = (token) => {
  try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return JSON.parse(jsonPayload);
  } catch (error) {
      console.error('Failed to decode token:', error);
      return null;
  }
};

const getTokenExpirationTimestamp = () => {
  const token = getToken();
  if (!token) return null;

  const decoded = parseJwt(token);
  return decoded ? decoded.exp * 1000 : null; // Convert to milliseconds
};

const isTokenExpired = () => {
  const expirationTimestamp = getTokenExpirationTimestamp();
  if (!expirationTimestamp) return true;

  return Date.now() > expirationTimestamp;
};

// Assurez-vous d'exporter `parseJwt` ici
export { getToken, setToken, removeToken, isTokenExpired, getTokenExpirationTimestamp, parseJwt };
