import axios from 'axios';

//const API_URL = 'http://localhost:8081/auth';
const API_URL = 'https://fsac.pro:8081/auth';

const login = async (email, password) => {
    const response = await axios.post(`${API_URL}/login`, {
        email,
        password
    });
    return response; // Ensure response contains the complete API response
};

const logout = () => {
    localStorage.removeItem('token');
};

const authService = {
    login,
    logout,
};

export default authService;
