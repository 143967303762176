import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

function PieceJustificativeModal({ show, handleClose, handleSave }) {
  const [pieceType, setPieceType] = useState('');
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const onSave = () => {
    handleSave(pieceType, file);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Ajouter une pièce justificative</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="pieceType">
            <Form.Label>Type de pièce</Form.Label>
            <Form.Control
              type="text"
              placeholder="Entrez le type de la pièce"
              value={pieceType}
              onChange={(e) => setPieceType(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formFile" className="mt-3">
            <Form.Label>Télécharger le fichier</Form.Label>
            <Form.Control type="file" onChange={handleFileChange} />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Annuler
        </Button>
        <Button variant="primary" onClick={onSave}>
          Sauvegarder
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PieceJustificativeModal;
