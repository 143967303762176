import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import dossierService from '../../services/dossierService';
import './UpdateDossierEnseignant.css';

// Convert file to Base64
const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            const base64String = reader.result.split(',')[1]; // Extract base64 string
            resolve(base64String);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file); // Read the file as a data URL
    });
};

const UpdateDossierEnseignant = () => {
    const { dossierId } = useParams(); // Get dossierId from the URL

    const [aePiece, setAePiece] = useState(null); // AE-Piece Justificative
    const [arPiece, setArPiece] = useState(null); // AR-Piece Justificative

    // Handle file change and encode to base64 for AE and AR
    const handleAeFileChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            try {
                const base64String = await convertFileToBase64(file);
                setAePiece(base64String); // Update AE-Piece with base64 content
            } catch (error) {
                console.error("Error converting AE-Piece to base64:", error);
            }
        }
    };

    const handleArFileChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            try {
                const base64String = await convertFileToBase64(file);
                setArPiece(base64String); // Update AR-Piece with base64 content
            } catch (error) {
                console.error("Error converting AR-Piece to base64:", error);
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate dossierId
        if (!dossierId || dossierId === 'undefined') {
            alert('Dossier ID is missing.');
            return;
        }

        try {
            const formData = {
                activitesEnseignement: {
                    piecesJustificatives: [
                        { typePiece: 'AE-Piece', contenu: aePiece },
                    ],
                },
                activitesRecherche: {
                    piecesJustificatives: [
                        { typePiece: 'AR-Piece', contenu: arPiece },
                    ],
                },
            };

            await dossierService.updatePiecesJustificatives(dossierId, formData);
            alert('Dossier mis à jour avec succès');
        } catch (error) {
            console.error('Erreur lors de la mise à jour du dossier:', error);
            alert('Échec de la mise à jour du dossier');
        }
    };

    return (
        <div className="update-dossier-container">
            <h2>Mettre à jour le dossier</h2>
            <form onSubmit={handleSubmit}>
                <table className="styled-table">
                    <thead>
                        <tr>
                            <th>Pièces Justificatives</th>
                            <th>Télécharger</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>AE-Piece Justificative</td>
                            <td>
                                <input className="file-input" type="file" accept=".pdf,.jpg,.png" onChange={handleAeFileChange} />
                            </td>
                        </tr>
                        <tr>
                            <td>AR-Piece Justificative</td>
                            <td>
                                <input className="file-input" type="file" accept=".pdf,.jpg,.png" onChange={handleArFileChange} />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <button type="submit" className="submit-btn">Envoyer</button>
            </form>
        </div>
    );
};

export default UpdateDossierEnseignant;
