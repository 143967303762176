import React, { useEffect, useState, useContext } from 'react';
import dossierService from '../../services/dossierService';
import { AuthContext } from '../../Context/AuthContext';
import { useNavigate } from 'react-router-dom';
import './TrackDossier.css';

const TrackDossier = () => {
    const { userId } = useContext(AuthContext);
    const [dossiers, setDossiers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchDossiers = async () => {
            try {
                const data = await dossierService.getDossiersByEnseignant(userId);
                setDossiers(data);
            } catch (err) {
                setError('Erreur lors de la récupération des dossiers');
                console.error('Error fetching dossiers:', err);
            } finally {
                setLoading(false);
            }
        };

        if (userId) {
            fetchDossiers();
        }
    }, [userId]);

    const handleViewClick = (dossierId) => {
        navigate(`/enseignant/track-dossier/view/${dossierId}`);
    };

    const handleUpdateClick = (dossierId) => {
        navigate(`/enseignant/track-dossier/update/${dossierId}`);
    };

    if (loading) {
        return <p>Chargement des dossiers...</p>;
    }

    if (error) {
        return <p className="error">{error}</p>;
    }

    return (
        <div className="track-dossier-container">
            <h1 className="text-info">Suivi des dossiers</h1>
            {dossiers.length === 0 ? (
                <table className="dossier-table">
                    <thead>
                        <tr>
                            <th>ID Dossier</th>
                            <th>Date de dépôt</th>
                            <th>Statut</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan="4" style={{ textAlign: 'center' }}>
                                Aucun dossier déposé
                            </td>
                        </tr>
                    </tbody>
                </table>
            ) : (
                <table className="dossier-table">
                    <thead>
                        <tr>
                            <th>ID Dossier</th>
                            <th>Date de dépôt</th>
                            <th>Statut</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dossiers.map((dossier) => (
                            <tr key={dossier.id}>
                                <td>{dossier.id}</td>
                                <td>{new Date(dossier.dateDepot).toLocaleDateString()}</td>
                                <td>{dossier.statut}</td>
                                <td>
                                    {dossier.statut === 'REJETE' ? (
                                        <button onClick={() => handleUpdateClick(dossier.id)}>
                                            Mettre à jour
                                        </button>
                                    ) : (
                                        <button onClick={() => handleViewClick(dossier.id)}>
                                            Voir
                                        </button>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default TrackDossier;
