import axios from 'axios';

//const API_URL = 'http://localhost:8081';
const API_URL = 'https://fsac.pro:8081';

const getAuthToken = () => {
    return localStorage.getItem('token');
};

const dossierService = {
    //c'est pour  le coordonnateur affichage des dossiers déposer par les enseignants
    getSubmittedDossiers: async () => {
        try {
            const response = await axios.get(`${API_URL}/dossiers/submitted`, {
                headers: {
                    'Authorization': `Bearer ${getAuthToken()}`
                }
            });
            return response.data;
        } catch (error) {
            if (error.response) {
                console.error('Error fetching dossiers:', error.response.status, error.response.data);
            } else {
                console.error('Error fetching dossiers:', error.message);
            }
            throw error;
        }
    },


    //la bare de recherche dans l'interface coordonnateur
    searchDossiers: async (category, query) => {
        try {
            const response = await axios.get(`${API_URL}/dossiers/search`, {
                params: { category, query },
                headers: {
                    'Authorization': `Bearer ${getAuthToken()}`
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error searching dossiers:', error);
            throw error;
        }
    },

    submitDossier: async (formData) => {
        try {
            const response = await axios.post(`${API_URL}/dossiers/deposer`, formData, {
                headers: {
                    'Authorization': `Bearer ${getAuthToken()}`,
                },
            });
            console.log('Response from server:', response.data); // Log server response
            return response.data;
        } catch (error) {
            console.error('Error submitting dossier:', error);
            throw error;
        }
    },





    //suivi d'un dossier
    getDossiersByEnseignant: async (enseignantId) => {
        try {
            const response = await axios.get(`${API_URL}/enseignant/dossiers`, {
                params: { enseignantId },
                headers: {
                    'Authorization': `Bearer ${getAuthToken()}`
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching dossiers:', error);
            throw error;
        }
    },
    //affichage des memebres de commissions dans le dropdown menu du coordonnateur
    getCommissionMembers: async () => {
        const response = await axios.get(`${API_URL}/dossiers/members-commissions`, {
            headers: {
                'Authorization': `Bearer ${getAuthToken()}`
            }
        });
        return response.data;
    }
    //affectation d'un dossier a un memebre de commission
    ,

    assignDossierToMember: async (dossierId, memberId, dueDate) => {
        try {
            const response = await axios.post(`${API_URL}/dossiers/assign`, {
                dossierId,
                memberId,
                dueDate
            }, {
                headers: {
                    'Authorization': `Bearer ${getAuthToken()}`,
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error assigning dossier to member:', error);
            throw error;
        }
    },
    // Affichage du dossier dans la page de commission
    getAssignedDossiers: async (memberId) => {
        try {
            const token = getAuthToken(); // Ensure the token is correctly retrieved
            if (!token) {
                throw new Error("Authentication token is missing.");
            }

            if (!memberId) {
                throw new Error("Member ID is required to fetch assigned dossiers.");
            }

            const response = await axios.get(`${API_URL}/dossiers/assigned-dossiers`, {
                params: { memberId },
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            return response.data;
        } catch (error) {
            console.error('Error fetching assigned dossiers:', error.message || error);
            throw error;
        }
    },
    //affichage de l'objet dans la page évaluation
    getDossierById: async (id) => {
        try {
            const token = getAuthToken();
            if (!token) {
                throw new Error("Authentication token is missing.");
            }

            console.log('Fetching dossier with ID:', id);
            const response = await axios.get(`${API_URL}/dossiers/${id}`, {
                headers: {
                    'Authorization': `Bearer ${getAuthToken()}`
                },


            });

            return response.data;
        } catch (error) {
            console.error('Error fetching dossier by ID:', error.message || error);
            throw error;
        }
    },
    updateDossier: async (dossierDTO) => {
        const token = getAuthToken();
        if (!token) {
            throw new Error("Authentication token is missing.");
        }

        try {
            const response = await axios.put(`${API_URL}/dossiers/${dossierDTO.id}`, dossierDTO, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error updating dossier:', error.message || error);
            throw error;
        }
    },
    //la mise a jour des fichiers interface enseignant chercheur 
    updatePiecesJustificatives: async (dossierId, formData) => {
        const token = getAuthToken();
        if (!token) {
            throw new Error("Authentication token is missing.");
        }
    
        try {
            const response = await axios.put(`${API_URL}/dossiers/${dossierId}/pieces-justificatives`, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',  // You can uncomment this if required
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error updating dossier:', error.message || error);
            throw error;
        }
    }
    

};

export default dossierService;