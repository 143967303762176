import React, { useState, useRef } from 'react';
import './DepotDossier.css';

function NestedDropdown({ options, defaultOption, onOptionSelect }) {
  const [isOpen, setIsOpen] = useState(false);
  const [currentOptions, setCurrentOptions] = useState(options);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');

  const toggleMenu = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    if (option.subOptions) {
      setBreadcrumbs([...breadcrumbs, currentOptions]);
      setCurrentOptions(option.subOptions);
    } else {
      setSelectedOption(option.text);
      if (option.text === 'AE - Pièces justificatives') {
        onOptionSelect(option.text);  // Notify parent to show modal
      } else {
        setIsOpen(false);  // Close the dropdown
        onOptionSelect(option.text);  // Notify parent to handle file upload
      }
    }
  };

  const handleBackClick = () => {
    const newOptions = breadcrumbs.pop();
    setCurrentOptions(newOptions);
    setBreadcrumbs([...breadcrumbs]);
  };

  return (
    <div className="nested-dropdown">
      <div className="select-btn" onClick={toggleMenu}>
        <span>{selectedOption || defaultOption}</span>
        <i className={`bx bx-chevron-down ${isOpen ? 'rotate' : ''}`}></i>
      </div>

      {isOpen && (
        <ul className="options">
          {breadcrumbs.length > 0 && (
            <li className="option back-option" onClick={handleBackClick}>
              <span>Retour</span>
              <i className="bx bx-chevron-left arrow-icon"></i>
            </li>
          )}
          {currentOptions.map((option) => (
            <li
              key={option.text}
              className={`option ${option.subOptions ? 'has-suboptions' : ''}`}
              onClick={() => handleOptionClick(option)}
            >
              <span>{option.text}</span>
              {option.subOptions && <i className="bx bx-chevron-right arrow-icon"></i>}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default NestedDropdown;
