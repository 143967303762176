import React, { useState, useEffect } from 'react';
import dossierService from '../../services/dossierService';
import './AssignModal.css';

const AssignModal = ({ isOpen, onClose, dossier }) => {
  const [selectedMember, setSelectedMember] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [commissionMembers, setCommissionMembers] = useState([]);
  
  // Calculate today's date in the format 'YYYY-MM-DD'
  const today = new Date().toISOString().split('T')[0];

  useEffect(() => {
    const fetchCommissionMembers = async () => {
      try {
        const members = await dossierService.getCommissionMembers();
        setCommissionMembers(members);
      } catch (error) {
        console.error('Error fetching commission members:', error);
      }
    };

    fetchCommissionMembers();
  }, []);

  const handleAssign = async (e) => {
    e.preventDefault();
    
    // Ensure the due date is not in the past
    if (dueDate < today) {
      alert("La date d'échéance ne peut pas être antérieure à aujourd'hui.");
      return;
    }

    try {
      await dossierService.assignDossierToMember(dossier.id, selectedMember, dueDate);
      alert('Dossier assigned successfully');
      onClose();
    } catch (error) {
      console.error('Error assigning dossier:', error);
      alert('Failed to assign dossier');
    }
  };

  if (!isOpen) return null;

  // Handle missing 'enseignantChercheur' data gracefully
  const teacherFullName = dossier.enseignantChercheur 
    ? `${dossier.enseignantChercheur.nom || 'Nom inconnu'} ${dossier.enseignantChercheur.prenom || 'Prénom inconnu'}`
    : 'Nom et prénom inconnus';

  return (
    <div className="assign-modal-overlay">
      <div className="assign-modal-content">
        <div className="assign-modal-header">
          <h2>Affecter un dossier</h2>
          <button className="assign-close-button" onClick={onClose}>&times;</button>
        </div>
        <div className="assign-modal-body">
          <form onSubmit={handleAssign}>
            <div className="assign-form-group">
              <label htmlFor="teacher">Nom Prénom de l'enseignant</label>
              <input
                type="text"
                id="teacher"
                name="teacher"
                value={teacherFullName}
                disabled
              />
            </div>
            <div className="assign-form-group">
              <label htmlFor="member">Membre de la commission</label>
              <select
                id="member"
                name="member"
                value={selectedMember}
                onChange={(e) => setSelectedMember(e.target.value)}
                required
              >
                <option value="">Sélectionner un membre</option>
                {commissionMembers.map(member => (
                  <option key={member.id} value={member.id}>
                    {member.nom} {member.prenom}
                  </option>
                ))}
              </select>
            </div>
            <div className="assign-form-group">
              <label htmlFor="due-date">Date d'échéance</label>
              <input
                type="date"
                id="due-date"
                name="due-date"
                value={dueDate}
                min={today}  
                onChange={(e) => setDueDate(e.target.value)}
                required
              />
            </div>
            <div className="assign-modal-footer">
              <button type="submit">Affecter</button>
              <button type="button" onClick={onClose}>Annuler</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AssignModal;
