import React, { useRef, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import dossierService from '../../services/dossierService';
import pdfLogo from '../../Assets/pdf_jpeg.jpg';
import RejectionModal from './RejectionModal';
import './CommissionEvaluation.css';
import { Button } from 'react-bootstrap';
import { FaRedo } from 'react-icons/fa';  // Import FaRedo icon


// Helper function to convert base64 to Blob
const base64StringToBlob = (base64, contentType = '') => {
    try {
        const byteCharacters = atob(base64);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: contentType });
    } catch (error) {
        console.error("Error converting base64 string to Blob:", error);
        return null;
    }
};


const renderContent = (contenu, fileName = 'document.pdf', contentType = 'application/pdf') => {
    if (!contenu) {
        return "Aucun fichier";
    }

    const blob = base64StringToBlob(contenu, contentType);
    const url = URL.createObjectURL(blob);

    // Remove the `download` attribute to open the file in a new tab instead of downloading directly
    return (
        <a href={url} download={fileName} target="_blank" rel="noopener noreferrer">
            <img src={pdfLogo} alt="File" style={{ width: '20px', height: '20px' }} />
        </a>
    );
};



// Helper function to map content titles to their data
const mapContentToTitle = (sectionData) => {
    const titleToContentMap = {};

    sectionData?.forEach((section) => {
        section.axes.forEach((axe) => {
            titleToContentMap[axe.titre] = axe.contenu;
        });
    });

    return titleToContentMap;
};

// Evaluation component starts here
const Evaluation = () => {
    const { dossierId } = useParams();
    const [dossier, setDossier] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [notes, setNotes] = useState({});
    const [validationError, setValidationError] = useState('');
    const [focusField, setFocusField] = useState(null);
    const inputRefs = useRef({});
    const [showRejectionModal, setShowRejectionModal] = useState(false);
    const navigate = useNavigate();
    const [loadingValide, setLoadingValide] = useState(false);

    useEffect(() => {
        if (focusField && inputRefs.current[focusField]) {
            inputRefs.current[focusField].focus();
        }
    }, [focusField]);

    // Fetch dossier data on component mount
    useEffect(() => {
        const fetchDossier = async () => {
            try {
                const data = await dossierService.getDossierById(dossierId);
                console.log("Dossier récupéré :", data);
                setDossier(data);
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };
    
        fetchDossier();
    }, [dossierId]);
    
    // Handle note change in the form
    const handleNoteChange = (sectionKey, title, value, index, axeIndex) => {
        const updatedNotes = { ...notes };
        if (!updatedNotes[sectionKey]) {
            updatedNotes[sectionKey] = {};
        }
        if (!updatedNotes[sectionKey][index]) {
            updatedNotes[sectionKey][index] = { axes: {} };
        }
        updatedNotes[sectionKey][index].axes[axeIndex] = { note: parseFloat(value) || 0 };
        setNotes(updatedNotes);
    };

    // Calculate the total note for a section
    const calculateNoteTotale = (axes) => {
        return axes.reduce((total, axe) => total + (axe.note || 0), 0);
    };

    // Update section with calculated notes
    const updateSection = (section, sectionKey) => {
        if (!section) return [];

        return section.map((item, index) => {
            const updatedAxes = item.axes.map((axe, axeIndex) => ({
                ...axe,
                note: notes[sectionKey]?.[index]?.axes?.[axeIndex]?.note || 0,
            }));
            return {
                ...item,
                axes: updatedAxes,
                noteTotale: calculateNoteTotale(updatedAxes),
            };
        });
    };

    // Validate section notes against max points
    const validateSectionNotes = (section, maxPoints, sectionName, sectionKey, index) => {
        if (!section) return true;
        const total = section.reduce((sum, item) => sum + item.noteTotale, 0);
        if (total > maxPoints) {
            setValidationError(`La section ${sectionName} dépasse le barème de ${maxPoints} points. Total obtenu: ${total} points.`);
            setFocusField(`${sectionKey}-${index}`);
            return false;
        }
        return true;
    };

    // Handle form submission for validation and submission
    const validateAndSubmit = async () => {
       

        const updatedActivitesEnseignement = {
            ...dossier.activitesEnseignement,
            productionPedagogiques: updateSection(dossier.activitesEnseignement.productionPedagogiques, 'productionPedagogiques'),
            encadrementPedagogiques: updateSection(dossier.activitesEnseignement.encadrementPedagogiques, 'encadrementPedagogiques'),
            responsabilitesPedagogiques: updateSection(dossier.activitesEnseignement.responsabilitesPedagogiques, 'responsabilitesPedagogiques'),
        };

        const updatedActivitesRecherche = {
            ...dossier.activitesRecherche,
            productionScientifiques: updateSection(dossier.activitesRecherche.productionScientifiques, 'productionScientifiques'),
            encadrementScientifiques: updateSection(dossier.activitesRecherche.encadrementScientifiques, 'encadrementScientifiques'),
            responsabilitesScientifiques: updateSection(dossier.activitesRecherche.responsabilitesScientifiques, 'responsabilitesScientifiques'),
            ouverturesValorisations: updateSection(dossier.activitesRecherche.ouverturesValorisations, 'ouverturesValorisations'),
        };

        // Validation des sections avec les barèmes
        const isSectionValid = (
            validateSectionNotes(updatedActivitesEnseignement.productionPedagogiques, 15, 'AE 1 - Production pédagogique', 'productionPedagogiques', 0) &&
            validateSectionNotes(updatedActivitesEnseignement.encadrementPedagogiques, 20, 'AE 2 - Encadrement pédagogique', 'encadrementPedagogiques', 0) &&
            validateSectionNotes(updatedActivitesEnseignement.responsabilitesPedagogiques, 15, 'AE 3 - Responsabilités pédagogiques et administratives', 'responsabilitesPedagogiques', 0) &&
            validateSectionNotes(updatedActivitesRecherche.productionScientifiques, 22, 'AR 1 - Production scientifique', 'productionScientifiques', 0) &&
            validateSectionNotes(updatedActivitesRecherche.encadrementScientifiques, 13, 'AR 2 - Encadrement scientifique', 'encadrementScientifiques', 0) &&
            validateSectionNotes(updatedActivitesRecherche.responsabilitesScientifiques, 10, 'AR 3 - Responsabilités scientifiques', 'responsabilitesScientifiques', 0) &&
            validateSectionNotes(updatedActivitesRecherche.ouverturesValorisations, 5, 'AR 4 - Ouverture et valorisation', 'ouverturesValorisations', 0)
        );

        if (!isSectionValid) {
            alert("Le dossier ne peut pas être validé car certaines sections dépassent les points autorisés.");
            return; // Stop submission if validation fails
        }

        const totalPointsEnseignement = updatedActivitesEnseignement.productionPedagogiques.reduce(
            (sum, pp) => sum + pp.noteTotale, 0
        ) + updatedActivitesEnseignement.encadrementPedagogiques.reduce(
            (sum, ep) => sum + ep.noteTotale, 0
        ) + updatedActivitesEnseignement.responsabilitesPedagogiques.reduce(
            (sum, rp) => sum + rp.noteTotale, 0
        );

        const totalPointsRecherche = updatedActivitesRecherche.productionScientifiques.reduce(
            (sum, ps) => sum + ps.noteTotale, 0
        ) + updatedActivitesRecherche.encadrementScientifiques.reduce(
            (sum, es) => sum + es.noteTotale, 0
        ) + updatedActivitesRecherche.responsabilitesScientifiques.reduce(
            (sum, rs) => sum + rs.noteTotale, 0
        ) + updatedActivitesRecherche.ouverturesValorisations.reduce(
            (sum, ov) => sum + ov.noteTotale, 0
        );

        if (totalPointsEnseignement > 50 || totalPointsRecherche > 50) {
            alert("Le dossier ne peut pas être validé car les activités dépassent les 50 points autorisés.");
            return; // Stop submission if total points exceed limit
        }

        const updatedDossier = {
            ...dossier,
            activitesEnseignement: {
                ...updatedActivitesEnseignement,
                totalPoints: totalPointsEnseignement,
            },
            activitesRecherche: {
                ...updatedActivitesRecherche,
                totalPoints: totalPointsRecherche,
            },
            statut: 'VALIDE'
        };

        console.log('Updated Dossier:', updatedDossier);

        try {
            const response = await dossierService.updateDossier(updatedDossier);
            console.log('Dossier validé successfully:', response.data);
            navigate('/membre-commision');
        } catch (error) {
            console.error('Error updating dossier:', error);
        }
    };

    const handleValider = async (e) => {
        e.preventDefault(); // Prevent the default form submission behavior
        setLoadingValide(true); // Set loading state to true
        if (window.confirm("Êtes-vous sûr de vouloir valider ce dossier ?")) {
            await validateAndSubmit();
        }
        setLoadingValide(false); // Set loading state back to false after submission
    };
    

    const handleRejectionSave = async (comment) => {
        if (!comment.trim()) {
            alert("Le commentaire ne peut pas être vide.");
            return;
        }

        try {
            const updatedDossier = {
                ...dossier,
                statut: 'REJETE',
                commentaire: comment
            };

            await dossierService.updateDossier(updatedDossier);
            setShowRejectionModal(false);
            alert('Dossier rejeté avec succès');
            navigate('/membre-commision');
        } catch (error) {
            console.error('Error rejecting dossier:', error.message || error);
            alert('Erreur lors du rejet du dossier');
        }
    };

    const handleRejeter = () => {
        setShowRejectionModal(true);
    };

    const renderPiecesJustificatives = (piecesJustificatives) => {
        return piecesJustificatives.map((piece, index) => (
            <tr key={index}>
                <td className='description-column'>Document justificatif {index + 1}</td>
                <td className='file-column'>
                    {piece.contenu ? (
                        renderContent(piece.contenu, `justificatif-${index + 1}.pdf`)
                    ) : (
                        "Aucun fichier"
                    )}
                </td>
            </tr>
        ));
    };

    const renderEvaluationTable = (sectionTitle, sectionKey, titleToContentMap, sectionData) => {
        return (
            <fieldset className="commission-evaluation-section">
                <legend>{sectionTitle}</legend>
                <div className='pdf-section'>
                    <table className='evaluation-table'>
                        <tbody>
                            {sectionData.map((item, index) =>
                                item.axes.map((axe, axeIndex) => (
                                    <tr key={`${sectionKey}-${index}-${axeIndex}`}>
                                        <td className='description-column'>{axe.titre}</td>
                                        <td className='file-column'>
                                            {titleToContentMap[axe.titre]
                                                ? renderContent(titleToContentMap[axe.titre], `document-${index + 1}.pdf`)
                                                : "Aucun fichier"}
                                        </td>
                                        <td className='note-column'>
                                            <label htmlFor={`${sectionKey}-${index}-note`}>Note :</label>
                                            <input
                                                type="number"
                                                id={`${sectionKey}-${index}-note`}
                                                placeholder="Note"
                                                className="note-input"
                                                value={notes[sectionKey]?.[index]?.axes?.[axeIndex]?.note || 0}
                                                onChange={(e) => handleNoteChange(sectionKey, axe.titre, e.target.value, index, axeIndex)}
                                                disabled={!titleToContentMap[axe.titre]}
                                            />
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            </fieldset>
        );
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;
    if (!dossier) return <p>No dossier found.</p>;

    return (
        <div className="commission-main">
            <h2 className='commissionTitle'>Évaluation du Dossier</h2>
            <div className="commission-evaluation-container">
                <fieldset className="commission-evaluation-dossier-info">
                    <legend className="axe-title">Informations personnelles</legend>

                    <p><strong>Nom:</strong> {dossier.enseignantChercheur?.nom || "Nom non disponible"}</p>
                    <p><strong>Prénom:</strong> {dossier.enseignantChercheur?.prenom || "Prénom non disponible"}</p>
                    <p><strong>Date de dépôt:</strong> {new Date(dossier.dateDepot).toLocaleDateString()}</p>
                </fieldset>

                <form>
                    <fieldset className="commission-evaluation-fieldset">
                        <legend className='axe-title'>AE - Activité d'enseignement / 50 pts</legend>

                        {dossier?.activitesEnseignement?.productionPedagogiques && renderEvaluationTable(
                            "AE 1 - Production pédagogique : (15 pts)",
                            'productionPedagogiques',
                            mapContentToTitle(dossier.activitesEnseignement.productionPedagogiques),
                            dossier.activitesEnseignement.productionPedagogiques
                        )}

                        {dossier?.activitesEnseignement?.encadrementPedagogiques && renderEvaluationTable(
                            "AE 2 - Encadrement pédagogique : (20 pts)",
                            'encadrementPedagogiques',
                            mapContentToTitle(dossier.activitesEnseignement.encadrementPedagogiques),
                            dossier.activitesEnseignement.encadrementPedagogiques
                        )}

                        {dossier?.activitesEnseignement?.responsabilitesPedagogiques && renderEvaluationTable(
                            "AE 3 - Responsabilités pédagogiques et administratives : (15 pts)",
                            'responsabilitesPedagogiques',
                            mapContentToTitle(dossier.activitesEnseignement.responsabilitesPedagogiques),
                            dossier.activitesEnseignement.responsabilitesPedagogiques
                        )}

                        {dossier?.activitesEnseignement?.piecesJustificatives?.length > 0 && (
                            <fieldset className="commission-evaluation-section">
                                <legend>AE Pièces justificatives</legend>
                                <div className='pdf-section'>
                                    <table className='evaluation-table'>
                                        <tbody>
                                            {renderPiecesJustificatives(dossier.activitesEnseignement.piecesJustificatives)}
                                        </tbody>
                                    </table>
                                </div>
                            </fieldset>
                        )}
                    </fieldset>

                    <fieldset className="commission-evaluation-fieldset">
                        <legend className='axe-title'>AR - Activité de recherche / 50 pts</legend>

                        {dossier?.activitesRecherche?.productionScientifiques && renderEvaluationTable(
                            "AR 1 - Production scientifique : (22 pts)",
                            'productionScientifiques',
                            mapContentToTitle(dossier.activitesRecherche.productionScientifiques),
                            dossier.activitesRecherche.productionScientifiques
                        )}

                        {dossier?.activitesRecherche?.encadrementScientifiques && renderEvaluationTable(
                            "AR 2 - Encadrement scientifique : (13 pts)",
                            'encadrementScientifiques',
                            mapContentToTitle(dossier.activitesRecherche.encadrementScientifiques),
                            dossier.activitesRecherche.encadrementScientifiques
                        )}

                        {dossier?.activitesRecherche?.responsabilitesScientifiques && renderEvaluationTable(
                            "AR 3 - Responsabilités scientifiques : (10 pts)",
                            'responsabilitesScientifiques',
                            mapContentToTitle(dossier.activitesRecherche.responsabilitesScientifiques),
                            dossier.activitesRecherche.responsabilitesScientifiques
                        )}

                        {dossier?.activitesRecherche?.ouverturesValorisations && renderEvaluationTable(
                            "AR 4 - Ouverture et valorisation : (5 pts)",
                            'ouverturesValorisations',
                            mapContentToTitle(dossier.activitesRecherche.ouverturesValorisations),
                            dossier.activitesRecherche.ouverturesValorisations
                        )}

                        {dossier?.activitesRecherche?.piecesJustificatives?.length > 0 && (
                            <fieldset className="commission-evaluation-section">
                                <legend>AR Pièces justificatives</legend>
                                <div className='pdf-section'>
                                    <table className='evaluation-table'>
                                        <tbody>
                                            {renderPiecesJustificatives(dossier.activitesRecherche.piecesJustificatives)}
                                        </tbody>
                                    </table>
                                </div>
                            </fieldset>
                        )}
                    </fieldset>

                    {dossier?.annexes?.length > 0 && (
                        <fieldset className="commission-evaluation-fieldset">
                            <legend>Annexes (I II III)</legend>
                            <div className='pdf-section'>
                                <table className='evaluation-table'>
                                    <tbody>
                                        {dossier.annexes.map((annexe, index) => (
                                            <tr key={index}>
                                                <td className='description-column'>{annexe.typeAnnexe}</td>
                                                <td className='file-column'>
                                                    {renderContent(annexe.contenu, `annexe-${index + 1}.pdf`)}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </fieldset>
                    )}
                    <div className='boutons-Evaluation'>
                    <Button variant="success" type="button" onClick={handleValider} disabled={loadingValide}>
                        {loadingValide ? (
                            <>
                                <FaRedo className="forgot-icon spin" /> Validation en cours...
                            </>
                        ) : (
                            <>
                                <FaRedo className="forgot-icon" /> Valider
                            </>
                        )}
                    </Button>


                    <Button variant="danger" onClick={handleRejeter}>
                            Rejeter
                        </Button>

                    </div>
                    <RejectionModal
                        show={showRejectionModal}
                        onHide={() => setShowRejectionModal(false)}
                        onSave={handleRejectionSave}
                    />
                </form>
            </div>
        </div>
    );
};

export default Evaluation;
