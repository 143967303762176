import React from 'react';
import './MainContent.css';

const MainContent = () => {
  return (
    <main className="main-content">
      <div className="card">
        <h2>Introduction</h2>
        <p>
          L'université souhaite améliorer le processus d'avancement de grade des enseignants-chercheurs en développant une application de gestion centralisée. Ce projet est crucial pour garantir la transparence, l'efficacité et l'équité du processus.
        </p>
      </div>
      <div className="card">
        <h2>Objectifs</h2>
        <p>
          • Faciliter le dépôt et la gestion des dossiers d'avancement par les enseignants-chercheurs.<br/>
          • Simplifier l'évaluation des dossiers par les membres de la commission scientifique.<br/>
          • Assurer un suivi efficace des dossiers par le coordonnateur de la commission.<br/>
          • Fournir des outils de reporting et d'archivage pour la gestion administrative.
        </p>
      </div>
      <div className="card">
        <h2>Description du Projet</h2>
        <p>
          L'application permet aux enseignants-chercheurs de déposer leurs dossiers d'avancement en ligne, incluant les documents et annexes requis. Les membres de la commission scientifique peuvent accéder à ces dossiers pour les évaluer selon une grille prédéfinie.
        </p>
      </div>
    </main>
  );
};

export default MainContent;
