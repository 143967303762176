import React, { useState } from 'react';
import userService from '../../services/userService';
import './CreateUserModal.css';

const CreateUserModal = ({ onClose, onSave }) => {
    const [userDetails, setUserDetails] = useState({
        nom: '',
        prenom: '',
        email: '',
    });

    const handleChange = (e) => {
        setUserDetails({
            ...userDetails,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Submitting user details:', userDetails);
        try {
            const createdUser = await userService.createUser(userDetails);
            console.log('User created:', createdUser);
            onSave(createdUser); // Call onSave to handle the new user in the parent component
            onClose(); 
        } catch (error) {
            console.error('Error creating user:', error);
        }
    };

    return (
        <div className="create-user-modal-container">
            <div className="create-user-modal-dialog">
                <div className="create-user-modal-content">
                    <div className="create-user-modal-header">
                        <h5 className="create-user-modal-title">Créer un Utilisateur</h5>
                        <button type="button" className="btn-close" onClick={onClose}></button>
                    </div>
                    <div className="create-user-modal-body">
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="nom" className="form-label">
                                    Nom
                                </label>
                                <input
                                    type="text"
                                    id="nom"
                                    name="nom"
                                    className="form-control"
                                    value={userDetails.nom}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="prenom" className="form-label">
                                    Prénom
                                </label>
                                <input
                                    type="text"
                                    id="prenom"
                                    name="prenom"
                                    className="form-control"
                                    value={userDetails.prenom}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    className="form-control"
                                    value={userDetails.email}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="create-user-modal-footer">
                                <button type="submit" className="btn btn-primary">
                                    Créer
                                </button>
                                <button type="button" className="btn btn-secondary" onClick={onClose}>
                                    Annuler
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateUserModal;
