import React, { useState, useEffect, useRef } from 'react';
import userService from '../../services/userService';
import { setupDropdown } from './script';
import './AdminPage.css';
import './searchbar.css';
import EditUserRoleModal from './EditUserRoleModal';
import './EditUserRoleModal.css';
import CreateUserModal from './CreateUserModal';
import './CreateUserModal.css';
import AdminHeader from './AdminHeader'; 

const AdminPage = () => {
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchCategory, setSearchCategory] = useState('Chercher par catégorie');
    const [searchError, setSearchError] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const dropdownCleanupRef = useRef(null);
    const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false);

    const filterNonAdminUsers = (users) => {
        return users.filter(user => !user.roles.includes('ADMIN'));
    };

    useEffect(() => {
        const fetchAndSetup = async () => {
            try {
                const response = await userService.getPaginatedUsers(currentPage);
                console.log('API response:', response); // Log the API response
                const nonAdminUsers = filterNonAdminUsers(response.content);
                setUsers(nonAdminUsers);
                setFilteredUsers(nonAdminUsers);
                setTotalPages(response.totalPages);
                setError(null);
            } catch (error) {
                console.error('Error fetching users:', error.response || error.message || error);
                setError('Failed to load users');
            } finally {
                setLoading(false);
            }

            const cleanup = setupDropdown();
            dropdownCleanupRef.current = cleanup;
        };

        fetchAndSetup();

        return () => {
            if (dropdownCleanupRef.current) {
                dropdownCleanupRef.current();
            }
        };
    }, [currentPage]);

    const handleSearchCategoryChange = (category) => {
        setSearchCategory(category);
        setSearchQuery('');
        setFilteredUsers(filterNonAdminUsers(users));
        setSearchError('');
    };

    const handleSearchQueryChange = (query) => {
        setSearchQuery(query);
        if (!query) {
            setFilteredUsers(filterNonAdminUsers(users));
            setSearchError('');
            return;
        }

        if (searchCategory === 'Chercher par catégorie') {
            setSearchError('Veuillez sélectionner une catégorie de recherche.');
            return;
        }

        const filtered = filterNonAdminUsers(users).filter((user) => {
            switch (searchCategory) {
                case 'nom':
                    return user.nom.toLowerCase().includes(query.toLowerCase());
                case 'prénom':
                    return user.prenom.toLowerCase().includes(query.toLowerCase());
                case 'email':
                    return user.email.toLowerCase().includes(query.toLowerCase());
                case 'role':
                    return user.roles.some((role) =>
                        role.toLowerCase().includes(query.toLowerCase())
                    );
                default:
                    return false;
            }
        });

        if (filtered.length === 0) {
            setSearchError(`Aucun résultat trouvé pour ${query} dans ${searchCategory}`);
        } else {
            setSearchError('');
        }

        setFilteredUsers(filtered);
    };

    const handleEditUser = (user, e) => {
        e.preventDefault();
        setSelectedUser(user);
        setShowModal(true);
    };

    const handleOpenCreateUserModal = () => {
        console.log('Opening Create User Modal');
        setIsCreateUserModalOpen(true);
    };

    const handleCloseCreateUserModal = () => {
        console.log('Closing Create User Modal');
        setIsCreateUserModalOpen(false);
    };

    const handleSaveUser = (newUser) => {
        setUsers([...users, newUser]);
        setFilteredUsers([...filteredUsers, newUser]);
    };

    const handleSaveRole = async (userId, newRole) => {
        try {
            await userService.changeUserRole(userId, newRole);
            const updatedUsers = users.map((user) =>
                user.id === userId ? { ...user, roles: [newRole] } : user
            );
            const filteredUpdatedUsers = filterNonAdminUsers(updatedUsers);
            setUsers(filteredUpdatedUsers);
            setFilteredUsers(filteredUpdatedUsers);
            setShowModal(false);
        } catch (error) {
            console.error('Error updating role:', error);
        }
    };

    const handlePageChange = (pageNumber) => {
        if (pageNumber < 0 || pageNumber >= totalPages) return;
        setCurrentPage(pageNumber);
    };

    if (loading) {
        return <div>Chargement...</div>;
    }

    if (error) {
        return <div>Erreur lors du chargement des utilisateurs: {error}</div>;
    }

    return (
        <div className="admin-container">
           <AdminHeader onOpenCreateUserModal={handleOpenCreateUserModal} />
           <h1 className='admin-title'>Liste des utilisateurs</h1>
            <div className="search-bar">
                <div className="dropdown">
                    <div id="drop-text" className="dropdown-text">
                        <span id="span">{searchCategory}</span>
                        <i id="icon" className="fa fa-chevron-down"></i>
                    </div>
                    <ul id="list" className="dropdown-menu">
                        <li className="dropdown-item" onClick={() => handleSearchCategoryChange('Chercher par catégorie')}>
                            Chercher par catégorie
                        </li>
                        <li className="dropdown-item" onClick={() => handleSearchCategoryChange('nom')}>
                            nom
                        </li>
                        <li className="dropdown-item" onClick={() => handleSearchCategoryChange('prénom')}>
                            prénom
                        </li>
                        <li className="dropdown-item" onClick={() => handleSearchCategoryChange('email')}>
                            email
                        </li>
                        <li className="dropdown-item" onClick={() => handleSearchCategoryChange('role')}>
                            role
                        </li>
                    </ul>
                </div>

                <div className="search-box">
                    <input
                        type="text"
                        id="search-input"
                        placeholder="rechercher"
                        value={searchQuery}
                        onChange={(e) => handleSearchQueryChange(e.target.value)}
                    />
                    <i className="fas fa-search"></i>
                </div>
            </div>

            {searchError && <div className="alert alert-danger">{searchError}</div>}

            <div className="table-responsive">
                <div className="table-wrapper">
                    <table className="table table-striped table-hover table-bordered">
                        <thead>
                            <tr>
                                <th>Nom <i className="fa fa-sort" /></th>
                                <th>Prénom</th>
                                <th>Adresse e-mail</th>
                                <th>Roles <i className="fa fa-sort" /></th>
                                <th>Changer Le rôle</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredUsers.length > 0 ? (
                                filteredUsers.map((user) => (
                                    <tr key={user.id}>
                                        <td>{user.nom}</td>
                                        <td>{user.prenom}</td>
                                        <td>{user.email}</td>
                                        <td>{user.roles.join(', ')}</td>
                                        <td>
                                            <button className="edit edit-button" title="Modifier" onClick={(e) => handleEditUser(user, e)}>
                                                <i className="material-icons">edit</i>
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5">Aucun utilisateur trouvé.</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <div className="pagination">
                        <ul className="pagination">
                            <li className={`page-item ${currentPage === 0 ? 'disabled' : ''}`}>
                                <button onClick={() => handlePageChange(currentPage - 1)}>&laquo;</button>
                            </li>
                            {[...Array(totalPages).keys()].map(number => (
                                <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                                    <button onClick={() => handlePageChange(number)}>{number + 1}</button>
                                </li>
                            ))}
                            <li className={`page-item ${currentPage === totalPages - 1 ? 'disabled' : ''}`}>
                                <button onClick={() => handlePageChange(currentPage + 1)}>&raquo;</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            {showModal && selectedUser && (
                <EditUserRoleModal
                    userId={selectedUser.id}
                    currentRole={selectedUser.roles[0]}
                    onSave={handleSaveRole}
                    onClose={() => setShowModal(false)}
                />
            )}

            {isCreateUserModalOpen && (
                <CreateUserModal onClose={handleCloseCreateUserModal} onSave={handleSaveUser} />
            )}
        </div>
    );
};

export default AdminPage;
