import axios from 'axios';
import { getToken } from '../utils/auth';

//const API_URL = 'http://localhost:8081/users';
const API_URL = 'https://fsac.pro:8081/users';

const fetchUserIdByEmail = async (email) => {
  try {
    const token = getToken();  // Get the JWT token
    const response = await axios.get(`${API_URL}/id`, {
      params: { email },
      headers: {
        Authorization: `Bearer ${token}`  // Include the token in the Authorization header
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching user ID:', error);
    throw error;
  }
};

// Make sure this export matches the function you are trying to import
export default {
  fetchUserIdByEmail,
};
