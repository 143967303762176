import React from 'react';
import './DownloadFiles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import AnnexeI from '../../Assets/AnnexeI.pdf';
import AnnexeII from '../../Assets/AnnexeII.pdf';
import AnnexeIII from '../../Assets/AnnexeIII.pdf';
import GrilleDavancement from '../../Assets/GrilleDavancement.pdf';

const DownloadFiles = () => {
    const files = [
        { name: 'Annexe I', url: AnnexeI },
        { name: 'Annexe II', url: AnnexeII },
        { name: 'Annexe III', url: AnnexeIII },
        { name: 'Grille d\'avancement', url: GrilleDavancement }
    ];

    return (
        <div className="download-files">
            <ul>
                {files.map(file => (
                    <li key={file.name}>
                        <a href={file.url} download>
                            <FontAwesomeIcon icon={faFilePdf} /> {file.name}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default DownloadFiles;
