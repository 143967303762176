// src/components/Enseignant/TeacherHeader.js
import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faFileUpload, faClipboardList, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import './TeacherHeader.css';
import logo from '../../Assets/FSAC_mini.png';
import { AuthContext } from '../../Context/AuthContext';

const TeacherHeader = () => {
  const navigate = useNavigate();
  const { logout } = useContext(AuthContext);

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <header className="teacher-header">
      <div className="header-container">
        <div className="logo">
          <Link to="/enseignant"><img src={logo} alt="FSAC Logo" /></Link>
        </div>
        <nav className="nav">
          <ul>
            <li><Link to="/enseignant/change-password"><FontAwesomeIcon icon={faUser} /> Changer le mot de passe</Link></li>
            <li><Link to="/enseignant/depot-dossier"><FontAwesomeIcon icon={faFileUpload} /> Dépôt de Dossier</Link></li>
            <li><Link to="/enseignant/track-dossier"><FontAwesomeIcon icon={faClipboardList} /> Consulter le Dossier</Link></li>
            <li><Link to="#" onClick={handleLogout}><FontAwesomeIcon icon={faSignOutAlt} /> Déconnexion</Link></li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default TeacherHeader;
