import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import dossierService from '../../services/dossierService';
import pdfLogo from '../../Assets/pdf_jpeg.jpg';
import './ViewDossier.css';

const ViewDossier = () => {
    const { dossierId } = useParams();
    const [dossier, setDossier] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchDossier = async () => {
            try {
                const data = await dossierService.getDossierById(dossierId);
                console.log("Dossier récupéré :", data);
                setDossier(data);
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };

        fetchDossier();
    }, [dossierId]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;
    if (!dossier) return <p>No dossier found.</p>;

    // Convert Base64 to Blob with the correct content type
    const base64StringToBlob = (base64, contentType) => {
        const byteCharacters = atob(base64);
        const byteArrays = [];
    
        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
    
        return new Blob(byteArrays, { type: contentType });
    };

    // Render the content with the correct file type
    const renderContent = (contenu, fileName, contentType = 'application/pdf') => {
        if (contenu) {
            const blob = base64StringToBlob(contenu, contentType);
            const url = URL.createObjectURL(blob);
            return (
                <a href={url} download={fileName || 'document.pdf'}>
                    <img src={pdfLogo} alt="File" style={{ width: '20px', height: '20px' }} />
                </a>
            );
        } else {
            return "Aucun fichier";
        }
    };

    // Map the section data to their titles and contents
    const mapContentToTitle = (sectionData) => {
        const titleToContentMap = {};
        sectionData?.forEach((section) => {
            section.axes.forEach((axe) => {
                if (axe.contenu) {
                    titleToContentMap[axe.titre] = axe.contenu;
                }
            });
        });
        return titleToContentMap;
    };

    // Render the table for each section
    const renderViewTable = (sectionTitle, titleToContentMap, sectionData, contentType = 'application/pdf') => {
        if (!sectionData || sectionData.length === 0 || sectionData.every(item => !item.axes || item.axes.length === 0)) {
            return (
                <fieldset className="view-section">
                    <legend>{sectionTitle}</legend>
                    <p>Aucune donnée disponible pour cette section.</p>
                </fieldset>
            );
        }

        return (
            <fieldset className="view-section">
                <legend>{sectionTitle}</legend>
                <div className='pdf-section'>
                    <table className='view-table'>
                        <tbody>
                            {sectionData.map((item, index) =>
                                item.axes.map((axe, axeIndex) => (
                                    axe.contenu && (
                                        <tr key={`${sectionTitle}-${index}-${axeIndex}`}>
                                            <td className='description-column'>{axe.titre || 'Sans titre'}</td>
                                            <td className='file-column'>
                                                {renderContent(axe.contenu, `document-${index + 1}.pdf`, contentType)}
                                            </td>
                                        </tr>
                                    )
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            </fieldset>
        );
    };

    return (
        <div className="view-dossier-container">
            <h2 className='view-title'>Détails du Dossier</h2>
            <div className="view-dossier-content">
                {dossier?.activitesEnseignement?.productionPedagogiques && renderViewTable(
                    "AE 1 - Production pédagogique",
                    mapContentToTitle(dossier.activitesEnseignement.productionPedagogiques),
                    dossier.activitesEnseignement.productionPedagogiques
                )}

                {dossier?.activitesEnseignement?.encadrementPedagogiques && renderViewTable(
                    "AE 2 - Encadrement pédagogique",
                    mapContentToTitle(dossier.activitesEnseignement.encadrementPedagogiques),
                    dossier.activitesEnseignement.encadrementPedagogiques
                )}

                {dossier?.activitesEnseignement?.responsabilitesPedagogiques && renderViewTable(
                    "AE 3 - Responsabilités pédagogiques et administratives",
                    mapContentToTitle(dossier.activitesEnseignement.responsabilitesPedagogiques),
                    dossier.activitesEnseignement.responsabilitesPedagogiques
                )}

                {dossier?.activitesEnseignement?.piecesJustificatives?.length > 0 && (
                    <fieldset className="view-section">
                        <legend>AE Pièces justificatives</legend>
                        <div className='pdf-section'>
                            <table className='view-table'>
                                <tbody>
                                    {dossier.activitesEnseignement.piecesJustificatives.map((piece, index) => (
                                        piece.contenu && (
                                            <tr key={index}>
                                                <td className='description-column'>Document justificatif {index + 1}</td>
                                                <td className='file-column'>
                                                    {renderContent(piece.contenu, `justificatif-${index + 1}.pdf`)}
                                                </td>
                                            </tr>
                                        )
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </fieldset>
                )}

                {dossier?.activitesRecherche?.productionScientifiques && renderViewTable(
                    "AR 1 - Production scientifique",
                    mapContentToTitle(dossier.activitesRecherche.productionScientifiques),
                    dossier.activitesRecherche.productionScientifiques
                )}

                {dossier?.activitesRecherche?.encadrementScientifiques && renderViewTable(
                    "AR 2 - Encadrement scientifique",
                    mapContentToTitle(dossier.activitesRecherche.encadrementScientifiques),
                    dossier.activitesRecherche.encadrementScientifiques
                )}

                {dossier?.activitesRecherche?.responsabilitesScientifiques && renderViewTable(
                    "AR 3 - Responsabilités scientifiques",
                    mapContentToTitle(dossier.activitesRecherche.responsabilitesScientifiques),
                    dossier.activitesRecherche.responsabilitesScientifiques
                )}

                {dossier?.activitesRecherche?.ouverturesValorisations && renderViewTable(
                    "AR 4 - Ouverture et valorisation",
                    mapContentToTitle(dossier.activitesRecherche.ouverturesValorisations),
                    dossier.activitesRecherche.ouverturesValorisations
                )}

                {dossier?.activitesRecherche?.piecesJustificatives?.length > 0 && (
                    <fieldset className="view-section">
                        <legend>AR Pièces justificatives</legend>
                        <div className='pdf-section'>
                            <table className='view-table'>
                                <tbody>
                                    {dossier.activitesRecherche.piecesJustificatives.map((piece, index) => (
                                        piece.contenu && (
                                            <tr key={index}>
                                                <td className='description-column'>Document justificatif {index + 1}</td>
                                                <td className='file-column'>
                                                    {renderContent(piece.contenu, `justificatif-${index + 1}.pdf`)}
                                                </td>
                                            </tr>
                                        )
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </fieldset>
                )}

                {dossier?.annexes?.length > 0 && (
                    <fieldset className="view-section">
                        <legend>Annexes (I II III)</legend>
                        <div className='pdf-section'>
                            <table className='view-table'>
                                <tbody>
                                    {dossier.annexes.map((annexe, index) => (
                                        annexe.contenu && (
                                            <tr key={index}>
                                                <td className='description-column'>{annexe.typeAnnexe}</td>
                                                <td className='file-column'>
                                                    {renderContent(annexe.contenu, `annexe-${index + 1}.pdf`)}
                                                </td>
                                            </tr>
                                        )
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </fieldset>
                )}
            </div>
        </div>
    );
};

export default ViewDossier;
