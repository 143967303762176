// src/components/Header/Header.js
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faExternalLinkAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import './Header.css';
import logo from '../../Assets/FSAC_mini.png';
import authService from '../../services/authService';

const Header = () => {
    const navigate = useNavigate();
    const token = localStorage.getItem('token'); // Vérifiez si un token existe
    const isAuthenticated = !!token; // Déterminez si l'utilisateur est authentifié

    const handleLogout = () => {
        authService.logout(); // Supprimez le token
        navigate('/login'); // Redirigez vers la page de connexion
    };

    return (
        <header className="header">
            <div className="header-container">
                <div className="logo">
                    <Link to="/"><img src={logo} alt="FSAC Logo" /></Link>
                </div>
                <nav className="nav">
                    <ul>
                        {isAuthenticated ? (
                            <li>
                                <Link to="/logout" onClick={(e) => {
                                    e.preventDefault();
                                    handleLogout();
                                }}>
                                    <FontAwesomeIcon icon={faUser} /> Déconnexion
                                </Link>
                            </li>
                        ) : (
                            <li>
                                <Link to="/login">
                                    <FontAwesomeIcon icon={faUser} /> Authentification
                                </Link>
                            </li>
                        )}
                        <li>
                            <a href="https://fsac.univh2c.ma/front/contact.html" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faEnvelope} /> Contact
                            </a>
                        </li>
                        <li>
                            <a href="https://ent.univh2c.ma/uPortal/f/welcome/normal/render.uP" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faExternalLinkAlt} /> Accès ENT
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    );
};

export default Header;
