import React, { useState } from 'react';
import axios from 'axios';
import { FaEnvelope, FaRedo } from 'react-icons/fa';
import './ForgotPassword.css';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [showButton, setShowButton] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Start loading indicator

    try {
      const response = await axios.post(`http://localhost:8081/users/forgot-password?email=${encodeURIComponent(email)}`);
      if (response.status === 200) {
        alert('Lien de réinitialisation du mot de passe envoyé');
        setShowButton(false);
      } else {
        alert('Échec de l\'envoi du lien de réinitialisation');
      }
    } catch (error) {
      console.error('Erreur lors de l\'envoi du lien de réinitialisation :', error.response ? error.response.data : error.message);
      if (error.response && error.response.data && error.response.data.message) {
        alert(`Échec de l'envoi du lien de réinitialisation : ${error.response.data.message}`);
      } else {
        alert('Échec de l\'envoi du lien de réinitialisation');
      }
    } finally {
      setLoading(false); // Stop loading indicator
    }
  };

  return (
    <div className="forgot-password-page">
      <div className="forgot-password-container">
        <h2>Réinitialisation du Mot de Passe</h2>
        <form onSubmit={handleSubmit}>
          <div className="forgot-form-group">
            <FaEnvelope className="forgot-icon" />
            <input
              type="email"
              placeholder="Entrez votre email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          {loading ? (
            <button type="button" disabled>
              <FaRedo className="forgot-icon spin" /> Envoi en cours...
            </button>
          ) : (
            showButton && (
              <button type="submit">
                <FaRedo className="forgot-icon" /> Réinitialiser le Mot de Passe
              </button>
            )
          )}
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
