import React, { useEffect, useState } from 'react';
import dossierService from '../../services/dossierService';
import './Coordonnateur.css';
import AssignModal from './AssignModal'; // Assurez-vous que le chemin est correct

const Coordonnateur = () => {
    const [dossiers, setDossiers] = useState([]);
    const [error, setError] = useState(null);
    const [searchCategory, setSearchCategory] = useState('nom');
    const [searchQuery, setSearchQuery] = useState('');
    const [noResults, setNoResults] = useState(false);
    const [selectedDossier, setSelectedDossier] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const fetchDossiers = async () => {
            try {
                const data = await dossierService.getSubmittedDossiers();
                console.log('Fetched dossiers:', data); // Check the structure here
                setDossiers(data);
                setNoResults(data.length === 0);
            } catch (error) {
                if (error.response) {
                    console.error('Error fetching dossiers:', error.response.data, error.response.status);
                } else {
                    console.error('Error fetching dossiers:', error.message);
                }
                setError(error.message);
            }
        };
    
        fetchDossiers();
    }, []);
    

    const handleSearch = async () => {
        try {
            const data = await dossierService.searchDossiers(searchCategory, searchQuery);
            setDossiers(data); // Assume searchDossiers already returns filtered data
            setNoResults(data.length === 0);
        } catch (error) {
            setError(error.message);
            console.error('Error searching dossiers:', error);
        }
    };

    const handleReset = async () => {
        setSearchCategory('nom');
        setSearchQuery('');
        try {
            const data = await dossierService.getSubmittedDossiers();
            setDossiers(data); // Use the data directly from backend
            setNoResults(false);
        } catch (error) {
            setError(error.message);
            console.error('Error fetching dossiers:', error);
        }
    };

    const handleAffecterClick = (dossier) => {
        setSelectedDossier(dossier);
        setIsModalOpen(true);
    };

    return (
        <div>
            <div className="coordonnateur-container">
                <fieldset className="coordonnateur-fieldset">
                    <legend>Recherches des dossiers par critères</legend>
                    <form>
                        <div className="coordonnateur-form-group">
                            <label htmlFor="category">Catégorie:</label>
                            <select
                                id="category"
                                name="category"
                                value={searchCategory}
                                onChange={(e) => setSearchCategory(e.target.value)}
                            >
                                <option value="nom">Nom</option>
                                <option value="prenom">Prénom</option>
                                <option value="datedepot">Date de dépôt</option>
                                <option value="statut">Statut</option>
                            </select>
                        </div>
                        <div className="coordonnateur-form-group">
                            <label htmlFor="query">Recherche:</label>
                            <input
                                type={searchCategory === 'datedepot' ? 'date' : 'text'}
                                id="query"
                                name="query"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </div>
                    </form>
                </fieldset>
                <div className="coordonnateur-buttons">
                    <button type="button" onClick={handleSearch}>Rechercher</button>
                    <button type="button" onClick={handleReset}>Annuler</button>
                </div>
                {error && <p className="error">Error: {error}</p>}
                {noResults && <p className="no-results">Aucun dossier trouvé pour cette recherche.</p>}
                {!noResults && (
                    <table className="coordonnateur-results-table">
                        <thead>
                            <tr>
                                <th>ID DOSSIER</th>
                                <th>Nom enseignant</th>
                                <th>Prénom enseignant</th>
                                <th>Date de dépôt</th>
                                <th>Statut</th>
                                <th>Affectation</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dossiers.map(dossier => (
                                <tr key={dossier.id}>
                                    <td>{dossier.id}</td>
                                    <td>{dossier.enseignantChercheur?.nom}</td>
                                    <td>{dossier.enseignantChercheur?.prenom}</td>
                                    <td>{new Date(dossier.dateDepot).toLocaleDateString()}</td>
                                    <td>{dossier.statut}</td>
                                    <td>
                                        <button className="coordonnateur-affecter-button" onClick={() => handleAffecterClick(dossier)}>
                                            Affecter
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>

                    </table>
                )}
            </div>

            {selectedDossier && (
    <AssignModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        dossier={selectedDossier}  // Ensure dossier is correctly passed
    />
)}

        </div>
    );
};

export default Coordonnateur;
