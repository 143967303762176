import React, { createContext, useEffect, useState } from 'react';
import getIdService from '../services/getIdService';
import { getToken, getTokenExpirationTimestamp, removeToken, setToken, parseJwt } from '../utils/auth';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setTokenState] = useState(() => getToken());
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const token = getToken();
    if (token) {
      const expirationTimestamp = getTokenExpirationTimestamp();
      return expirationTimestamp > Date.now();
    }
    return false;
  });
  const [userId, setUserId] = useState(null);

  const login = (token, expirationTimestamp) => {
    try {
      setToken(token, expirationTimestamp);
      setTokenState(token);
      const decodedToken = parseJwt(token);
      setUserId(decodedToken?.id);
      setIsAuthenticated(true);
    } catch (error) {
      console.error('Login failed in context:', error);
    }
  };

  const logout = () => {
    removeToken();
    setTokenState(undefined);
    setIsAuthenticated(false);
    setUserId(null);
  };

  useEffect(() => {
    if (!token) return;

    const expirationTimestamp = getTokenExpirationTimestamp();
    if (expirationTimestamp <= Date.now()) {
      setIsAuthenticated(false);
      logout();
      return;
    }

    const fetchUserId = async () => {
      try {
        const decodedToken = parseJwt(token);
        const email = decodedToken.sub;
        const fetchedUserId = await getIdService.fetchUserIdByEmail(email);
        setUserId(fetchedUserId);
        setIsAuthenticated(true);
      } catch (error) {
        console.error("Failed to fetch user ID:", error);
        logout();
      }
    };

    fetchUserId();

    const id = setTimeout(() => {
      setIsAuthenticated(false);
      logout();
    }, expirationTimestamp - Date.now());

    return () => {
      clearTimeout(id);
    };
  }, [token]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, userId, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
