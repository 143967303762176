import React from 'react';
import Slider from 'react-slick';
import './ImageSlider.css';
import image1 from '../../Assets/image1.jpg';
import image2 from '../../Assets/image2.jpg';
import image4 from '../../Assets/image4.PNG';

const ImageSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    customPaging: (i) => <div className="dot"></div>
  };

  return (
    <section id="slider">
      <div className="slider-container">
        <Slider {...settings}>
          <div className="mySlides">
            <img src={image1} alt="Slide 1" />
          </div>
          <div className="mySlides">
            <img src={image2} alt="Slide 2" />
          </div>
          <div className="mySlides">
            <img src={image4} alt="Slide 4" />
          </div>
        </Slider>
      </div>
    </section>
  );
};

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} slick-next`}
      style={{ ...style }}
      onClick={onClick}
    />
  );
};

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} slick-prev`}
      style={{ ...style }}
      onClick={onClick}
    />
  );
};

export default ImageSlider;
