import React, { useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash, FaLock, FaRedo } from 'react-icons/fa';
import './ResetPassword.css';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const token = new URLSearchParams(location.search).get('token');

  const handlePasswordReset = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError('Les mots de passe ne correspondent pas.');
      return;
    }

    try {
      const response = await axios.post('http://localhost:8081/users/reset-password', {
        token,
        newPassword: password,
      });

      setMessage(response.data.message);
      setError('');
      setTimeout(() => navigate('/Login'), 2000);
    } catch (error) {
      console.error('Erreur lors de la réinitialisation du mot de passe :', error);
      setError('Échec de la réinitialisation du mot de passe. Veuillez réessayer.');
      setMessage('');
    }
  };

  return (
    <div className="reset-password-page">
      <div className="reset-password-container">
        <h2>Créer un Nouveau Mot de Passe</h2>
        {error && <p className="reset-error">{error}</p>}
        {message && <p className="reset-message">{message}</p>}
        <form onSubmit={handlePasswordReset}>
          <div className="reset-form-group">
            <FaLock className="reset-icon" />
            <div className="reset-password-input-container">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Entrez votre nouveau mot de passe"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <span onClick={() => setShowPassword(!showPassword)} className="password-toggle-icon">
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
          </div>
          <div className="reset-form-group">
            <FaLock className="reset-icon" />
            <div className="reset-password-input-container">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Confirmez votre mot de passe"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
              <span onClick={() => setShowPassword(!showPassword)} className="password-toggle-icon">
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
          </div>
          <button type="submit">
            <FaRedo className="reset-icon" /> Mettre à Jour le Mot de Passe
          </button>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
