import React from 'react';
import './EnseignantChercheurPage.css';
import DownloadFiles from './DownloadFiles';
const EnseignantChercheurPage = () => {
    return (
        <div className="instructions-fieldset-container">
            <fieldset className="instructions-fieldset">
                <legend>Instructions pour le Dépôt de Dossier</legend>
                <p>Bienvenue sur la plateforme de dépôt de dossiers. Voici les étapes à suivre pour soumettre votre dossier avec succès :</p>

                <ol>
                    <li><strong>Cliquez sur le bouton "Déposer un dossier"</strong> : Pour commencer le processus de dépôt, cliquez sur le bouton au-dessus.</li>

                    <li><strong>Sélection des Activités</strong> :
                        <ul>
                            <li>Chaque dossier se compose d'une ou plusieurs activités, que ce soit une activité de recherche ou d'enseignement.</li>
                            <li>Pour ajouter une activité à votre dossier, cliquez sur le menu déroulant "Sélectionner une activité" et choisissez l'axe spécifique qui correspond à votre activité.</li>
                        </ul>
                    </li>

                    <li><strong>Téléchargement de Pièces Justificatives</strong> :
                        <ul>
                            <li>En fonction de l'activité choisie, vous avez la possibilité de télécharger des documents supplémentaires sous forme de pièces justificatives.</li>
                            <li>Ces pièces peuvent être utilisées pour justifier ou compléter les informations fournies dans votre dossier.</li>
                        </ul>
                    </li>

                    <li><strong>Téléchargement des Annexes</strong> :
                        <ul>
                            <li>Assurez-vous de télécharger les annexes nécessaires. Ces annexes doivent être scannées et téléchargées en fonction de ce qui est requis pour votre dossier.</li>
                            <li>Vous pouvez gérer les annexes de la même manière que les autres documents.</li>
                            <DownloadFiles />
                        </ul>
                    </li>

                    <li><strong>Gestion des Documents</strong> :
                        <ul>
                            <li>Vous avez la possibilité de supprimer un document que vous avez déjà téléchargé et de le remplacer par un autre si nécessaire.</li>
                            <li>Utilisez cette fonctionnalité pour vous assurer que tous les documents soumis sont corrects et complets.</li>
                        </ul>
                    </li>

                    <li><strong>Lecture des Instructions</strong> :
                        <ul>
                            <li>Veuillez lire attentivement ces instructions avant de soumettre votre dossier pour vous assurer que toutes les étapes sont complétées correctement.</li>
                        </ul>
                    </li>
                </ol>

                <p>Merci de suivre ces instructions pour garantir que votre dossier est soumis correctement et sans erreurs.</p>
            </fieldset>
        </div>
    );
};

export default EnseignantChercheurPage;
