import React from 'react';
import ImageSlider from './ImageSlider';
import MainContent from './MainContent';
import './Home.css';

const Home = () => {
  return (
    <div >
      <ImageSlider />
      <div className="home-container">
      <MainContent />
      </div>
      
    </div>
  );
};

export default Home;
