import React, { useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FaEnvelope, FaLock, FaSignInAlt } from 'react-icons/fa';
import './Login.css';
import { AuthContext } from '../../Context/AuthContext';
import authService from '../../services/authService';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);

  const handleLogin = async () => {
    try {
      const response = await authService.login(email, password);

      if (response.status === 200) {
        const data = response.data;
        const token = data.token;
        const expirationTimestamp = Date.now() + (+data.expiresIn || 60 * 60 * 1000);
        const roles = data.roles;

        login(token, expirationTimestamp); // Set isAuthenticated to true

        if (roles.includes('ADMIN')) {
          navigate('/admin');
        } else if (roles.includes('ENSEIGNANT_CHERCHEUR')) {
          navigate('/enseignant');
        } else if (roles.includes('MEMBRE_COMMISSION')) {
          navigate('/membre-commision');
        } else if (roles.includes('COORDONNATEUR')) {
          navigate('/coordonnateur');
        } else {
          alert('Role non reconnu');
        }
      } else {
        console.error(`Error ${response.status}: ${response.statusText}`);
        alert('Email ou mot de passe incorrect');
      }
    } catch (error) {
      console.error('Erreur lors de la connexion :', error);
      alert('Erreur lors de la connexion');
    }
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <h2>Connexion</h2>
        <form onSubmit={(e) => { e.preventDefault(); handleLogin(); }}>
          <div className="login-form-group">
            <FaEnvelope className="login-icon" />
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="login-form-group">
            <FaLock className="login-icon" />
            <input
              type="password"
              placeholder="Mot de passe"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit"><FaSignInAlt className="login-icon" /> Connexion</button>
          <div className="login-links">
            <Link to="/forgot-password">Mot de passe oublié ?</Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
